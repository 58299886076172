import React from 'react';
import { useIntl } from 'react-intl';
import { Stepper } from '@skiwo/components';
import { useGetJobQuery } from '../../Api/Endpoints/Jobs/Jobs.hooks';
import getJobStatusName from '../../Jobs/utils/getJobStatusName';
import { ManagerJobRiskStatus, ManagerJobStatus } from '../../types/ManagerJob';
import useGetJobIdFromParam from '../hooks/useGetJobIdFromParam';
import { jobSectionVisibilityGuard } from '../utils/jobSectionVisibilityGuard';
import { ActionButtonsSection } from './ActionButtonsSection/ActionButtonsSection';
import FinanceSection from './FinanceSection/FinanceSection';
import InterpreterCard from './InterpreterCard/InterpreterCard';
import JobFooter from './JobFooter/JobFooter';
import RequirementsSection from './RequirementsSection/RequirementsSection';
import { RiskFactorsSection } from './RiskFactorsSection/RiskFactorsSection';
import Statement from './Statement/Statement';
import InfoSection from './InfoSection';
import styles from './JobDetails.module.scss';

export const JobDetails = () => {
  const intl = useIntl();
  const jobId = useGetJobIdFromParam();
  const { data: job } = useGetJobQuery(jobId);
  if (!job) return null;

  const statuses = [
    ManagerJobStatus.Published,
    ManagerJobStatus.Applied,
    ManagerJobStatus.Accepted,
    ManagerJobStatus.InProgress,
    ManagerJobStatus.Finished,
  ];

  return (
    <section className={styles.orderSection}>
      <Stepper
        steps={statuses.map((status) => getJobStatusName(status, intl))}
        currentStep={statuses.indexOf(job.status) + 1}
      />
      <InfoSection />
      <ActionButtonsSection />
      {job.risk.status !== ManagerJobRiskStatus.NoRisk && <RiskFactorsSection risk={job.risk} />}
      <RequirementsSection />
      {jobSectionVisibilityGuard({
        currentStatus: job.status,
        statuses: [
          ManagerJobStatus.Accepted,
          ManagerJobStatus.InProgress,
          ManagerJobStatus.Finished,
        ],
      }) && <InterpreterCard />}
      {jobSectionVisibilityGuard({
        currentStatus: job.status,
        statuses: [ManagerJobStatus.Finished],
      }) && <Statement />}
      {jobSectionVisibilityGuard({
        currentStatus: job.status,
        statuses: [
          ManagerJobStatus.Accepted,
          ManagerJobStatus.InProgress,
          ManagerJobStatus.Finished,
        ],
      }) && <FinanceSection />}
      <JobFooter />
    </section>
  );
};

export default JobDetails;
