import React, { useEffect, useState } from 'react';
import { Form as B_Form, FormControlProps } from 'react-bootstrap';
import {
  faArrowUpFromLine,
  faEye,
  faEyeSlash,
  faTriangleExclamation,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import styles from './TextField.module.scss';

interface Props {
  name?: string;
  size?: 'medium' | 'large';
  label?: string;
  type?: 'text' | 'email' | 'password' | 'search' | 'number';
  hint?: string;
  errorText?: string;
  required?: boolean;
  textArea?: boolean;
  rows?: number;
  icon?: React.ReactNode;
  decorationView?: React.ReactNode;
  autocomplete?: boolean;
  maxLength?: number;
  textAlign?: 'left' | 'center' | 'right';
  'data-testid'?: string;
}

const TextField = React.forwardRef((props: Props & Omit<FormControlProps, 'size'>, ref) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [isCapsLockOn, setIsCapsLockOn] = useState(false);
  const {
    size = 'medium',
    type = 'text',
    label,
    required,
    autocomplete = true,
    textArea,
    rows = 4,
    hint,
    icon,
    name,
    errorText,
    isInvalid,
    maxLength,
    decorationView,
    textAlign,
    'data-testid': dataTestId,
    ...otherProps
  } = props;

  useEffect(() => {
    document.addEventListener('keydown', checkCapsLock);
    document.addEventListener('keyup', checkCapsLock);
  }, []);

  const getSize = () => {
    if (size == 'large') {
      return 'lg';
    }

    return undefined;
  };

  const togglePasswordVisible = () => {
    setPasswordVisible(!passwordVisible);
  };

  const checkCapsLock = (event: globalThis.KeyboardEvent) => {
    if (event.getModifierState && event.getModifierState('CapsLock')) {
      setIsCapsLockOn(true);
    } else {
      setIsCapsLockOn(false);
    }
  };

  const handleWheel = (event: React.WheelEvent<HTMLInputElement>) => {
    if ((event.target as HTMLInputElement).type === 'number') {
      (event.target as HTMLInputElement).blur();
    }
  };

  return (
    <B_Form.Group
      data-testid={`textfield-${getSize() === 'lg' ? 'lg' : 'md'}`}
      className={styles.inputGroup}
    >
      {label && (
        <B_Form.Label>
          <span data-testid="textfield-label">{label}</span>

          {required && <span data-testid="textfield-label-required">*</span>}
        </B_Form.Label>
      )}

      <div className={styles.inputContainer}>
        {icon && (
          <span className={styles.decorationIcon} data-testid="decoration-icon">
            {icon}
          </span>
        )}

        {textArea ? (
          <B_Form.Control
            {...(autocomplete ? {} : { autoComplete: 'nope' })}
            {...otherProps}
            type={passwordVisible ? 'text' : type}
            size={getSize()}
            data-testid={dataTestId}
            className={classnames(styles.input, {
              [styles.hasDecorationIcon]: !!icon,
              [styles[`textAlign--${textAlign}`]]: textAlign,
            })}
            name={name}
            id={name}
            {...(textArea ? { as: 'textarea' } : { as: 'input' })}
            {...(textArea ? { rows } : {})}
            {...(maxLength ? { maxLength } : {})}
            isInvalid={!!errorText || isInvalid}
            onWheel={handleWheel}
          />
        ) : (
          <B_Form.Control
            {...(autocomplete ? {} : { autoComplete: 'nope' })}
            {...otherProps}
            type={passwordVisible ? 'text' : type}
            size={getSize()}
            data-testid={dataTestId}
            className={classnames(styles.input, {
              [styles.hasDecorationIcon]: !!icon,
              [styles[`textAlign--${textAlign}`]]: textAlign,
            })}
            name={name}
            id={name}
            ref={ref}
            {...(maxLength ? { maxLength } : {})}
            isInvalid={!!errorText || isInvalid}
            onWheel={handleWheel}
          />
        )}
        {decorationView && <span className={styles.decorationView}>{decorationView}</span>}
        {type === 'password' && (
          <span className={styles.passwordIcons}>
            {isCapsLockOn && (
              <span>
                <FontAwesomeIcon
                  icon={faArrowUpFromLine}
                  data-testid="caps-lock-icon"
                  className={styles.capsLock}
                />
              </span>
            )}

            <span onClick={togglePasswordVisible}>
              <FontAwesomeIcon
                data-testid={passwordVisible ? 'hide-icon' : 'reveal-icon'}
                icon={passwordVisible ? faEye : faEyeSlash}
                className={classnames(styles.passwordToggle, { [styles.capsLockOn]: isCapsLockOn })}
              />
            </span>
          </span>
        )}
      </div>

      {errorText && (
        <B_Form.Text className={styles.errorMessage}>
          <span>
            <FontAwesomeIcon icon={faTriangleExclamation} />
          </span>
          <span data-testid="textfield-error-text">{errorText}</span>
        </B_Form.Text>
      )}

      {hint && (
        <B_Form.Text className={styles.hint}>
          <span data-testid="textfield-hint">{hint}</span>
        </B_Form.Text>
      )}
    </B_Form.Group>
  );
});
TextField.displayName = 'TextField';

export default TextField;
