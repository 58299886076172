import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Table } from '@skiwo/components';
import translationKeys from '../../../../translations/translationKeys';
import {
  ManagerJobDetails,
  ManagerJobSessionType,
  ManagerJobStatus,
} from '../../../../types/ManagerJob';
import { ManagerJobStatement } from '../../../../types/ManagerJobStatement';
import ActualFinishRow from './ActualFinishRow';
import ActualStartRow from './ActualStartRow';
import ChargePayRow from './ChargePayRow';
import DelayCausedByRow from './DelayCausedByRow';
import DietEnabledRow from './DietEnabledRow';
import ExpensesRow from './ExpensesRow';
import LastSavedRow from './LastSavedRow';
import NotPerformeredAsOrderedRow from './NotPerformedAsOrderedRow';
import ReceiptsRow from './ReciptsRow';
import ShowedUpRow from './ShowedUpRow';
import TravelDistanceRow from './TravelDistanceRow';
import TravelMethodRow from './TravelMethodRow';
import TravelTimeRow from './TravelTimeRow';
import styles from './StatementForm.module.scss';

interface StatementFormProps {
  statement: ManagerJobStatement;
  job: ManagerJobDetails;
}

export const StatementForm = ({ statement, job }: StatementFormProps) => {
  const isFeedbackLocked = job.isLocked;
  const isCancelled = job.status === ManagerJobStatus.Cancelled;
  const isInPersonSessionType =
    job.interpretationRequirement.sessionType === ManagerJobSessionType.InPerson;
  const isCancelledAndNotInPerson = isCancelled && !isInPersonSessionType;

  return !isCancelledAndNotInPerson ? (
    <Table striped>
      <thead>
        <tr>
          <th></th>
          <th className={styles.header}>
            <FormattedMessage id={translationKeys.job_statement_customer_feedback} />
          </th>
          <th className={styles.header}>
            <FormattedMessage id={translationKeys.job_statement_interpreter_feedback} />
          </th>
          {isFeedbackLocked && (
            <>
              <th className={styles.header}>
                <FormattedMessage id={translationKeys.job_statement_customer_decision} />
              </th>
              <th className={styles.header}>
                <FormattedMessage id={translationKeys.job_statement_interpreter_decision} />
              </th>
            </>
          )}
        </tr>
      </thead>
      <tbody className={styles.body}>
        <ChargePayRow isFeedbackLocked={isFeedbackLocked} />
        {!isCancelled && (
          <ShowedUpRow
            supplierFeedbackShowedUp={statement.supplierFeedback?.showedUp}
            demanderFeedbackShowedUp={statement.demanderFeedback?.showedUp}
            isFeedbackLocked={isFeedbackLocked}
          />
        )}
        {/* TODO: Travel started on cancelled status */}
        {!isCancelled && <NotPerformeredAsOrderedRow isFeedbackLocked={isFeedbackLocked} />}
        {!isCancelled && (
          <ActualStartRow
            jobStart={job.interpretationRequirement.startTime}
            demanderFeedbackActualStartTime={statement.demanderFeedback?.actualStartTime}
            supplierFeedbackActualStartTime={statement.supplierFeedback?.actualStartTime}
            isFeedbackLocked={isFeedbackLocked}
          />
        )}
        {!isCancelled && (
          <ActualFinishRow
            jobFinish={job.interpretationRequirement.finishTime}
            demanderFeedbackActualFinishTime={statement.demanderFeedback?.actualFinishTime}
            supplierFeedbackActualFinishTime={statement.supplierFeedback?.actualFinishTime}
            isFeedbackLocked={isFeedbackLocked}
          />
        )}
        {!isCancelled && (
          <DelayCausedByRow
            demanderFeedbackDelayCausedBy={statement.demanderFeedback?.delayCausedBy}
            supplierFeedbackDelayCausedBy={statement.supplierFeedback?.delayCausedBy}
            isFeedbackLocked={isFeedbackLocked}
          />
        )}
        <ExpensesRow
          supplierFeedbackExpenses={statement.supplierFeedback?.travelExpensesAmount}
          isFeedbackLocked={isFeedbackLocked}
        />
        {isInPersonSessionType && (
          <>
            <ReceiptsRow
              receipts={statement.supplierFeedback?.receipts}
              isFeedbackLocked={isFeedbackLocked}
            />
            <TravelMethodRow
              supplierFeedbackTravelType={statement.supplierFeedback?.travelType}
              isFeedbackLocked={isFeedbackLocked}
            />
            <TravelDistanceRow
              supplierTravelDistance={statement.supplierFeedback?.travelDistance}
              isFeedbackLocked={isFeedbackLocked}
            />
            <TravelTimeRow
              supplierTravelTime={statement.supplierFeedback?.travelTime}
              isFeedbackLocked={isFeedbackLocked}
            />
            <DietEnabledRow isFeedbackLocked={isFeedbackLocked} />
          </>
        )}
        <LastSavedRow
          supplierFeedbackLastUpdateLog={statement.supplierFeedback?.lastUpdateLog}
          demanderFeedbackLastUpdateLog={statement.demanderFeedback?.lastUpdateLog}
          supplierLastUpdateLog={statement.statement?.lastUpdateLog || undefined}
          isFeedbackLocked={isFeedbackLocked}
        />
      </tbody>
    </Table>
  ) : (
    <> </>
  );
};

export default StatementForm;
