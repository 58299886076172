import { ManagerJobBookingMechanism, ManagerJobSessionType } from './ManagerJob';

export enum ManagerJobStatementDelayCausedBy {
  Demander = 'demander',
  Supplier = 'supplier',
}

export enum ManagerJobStatementTravelType {
  None = 'none',
  PublicTransport = 'public_transport',
  Car = 'car',
}

export enum ManagerJobStatementSupplierTravelType {
  Default = 'default',
  PublicTransport = 'public_transport',
  Car = 'car',
  Flight = 'flight',
}

export enum ManagerJobStatementInterpreterTravelled {
  Right = 'right',
  Wrong = 'wrong',
  ChangedSessionType = 'changed_session_type',
}

export interface ManagerJobStatementLastUpdateLog {
  id: number;
  actorRole: string;
  actorName: string;
  createdAt: string;
}

export interface ManagerJobStatementReceipt {
  fileName: string;
  fileUrl: string;
}

interface NewStatement {
  sessionType: ManagerJobSessionType;
  chargeable: boolean;
  payable: boolean;
  demanderShowedUp: boolean;
  demanderInvoiceDiscountPercentage: number;
  demanderActualStartTime: string;
  demanderActualFinishTime: string;
  demanderDelayCausedBy: ManagerJobStatementDelayCausedBy | null;
  demanderTravelType: ManagerJobStatementTravelType | null;
  demanderTravelDistance: number;
  demanderTravelTime: number;
  demanderTravelDietEnabled: boolean;
  demanderTravelExpensesAmountOverride: string | null;
  demanderInflationDate: string;
  supplierShowedUp: boolean;
  supplierInvoiceDiscountPercentage: number;
  supplierActualStartTime: string;
  supplierActualFinishTime: string;
  supplierDelayCausedBy: ManagerJobStatementDelayCausedBy | null;
  supplierTravelType: ManagerJobStatementTravelType | null;
  supplierTravelDistance: number;
  supplierTravelTime: number;
  supplierTravelDietEnabled: boolean;
  supplierTravelExpensesAmountOverride: string | null;
  supplierInflationDate: string;
}

interface Statement extends NewStatement {
  id: number;
  lastUpdateLog: ManagerJobStatementLastUpdateLog | null;
}

export interface ManagerJobUpdateStatement {
  job: {
    actualCreatedAt?: string;
    bookingMechanism?: ManagerJobBookingMechanism;
  };
  info: {
    manualBookingFeeApplied?: boolean;
    manualEditingFeeApplied?: boolean;
    manualTravelBookingFeeApplied?: boolean;
  };
  statement?: Partial<NewStatement>;
}

export interface ManagerJobStatement {
  statement: Statement | null;
  demanderFeedback: {
    id: number;
    showedUp: boolean;
    jobCompleted: boolean;
    jobCompletedByColleague: boolean;
    actualStartTime: string;
    actualFinishTime: string;
    delayCausedBy: ManagerJobStatementDelayCausedBy;
    lastUpdateLog: ManagerJobStatementLastUpdateLog;
  } | null;
  supplierFeedback: {
    id: number;
    showedUp: boolean;
    jobCompleted: boolean;
    actualStartTime: string;
    actualFinishTime: string;
    delayCausedBy: ManagerJobStatementDelayCausedBy;
    interpreterTravelled: ManagerJobStatementInterpreterTravelled;
    travelType: ManagerJobStatementSupplierTravelType;
    travelDistance: number;
    travelTime: number;
    travelExpensesAmount: string;
    receipts: ManagerJobStatementReceipt[];
    lastUpdateLog: ManagerJobStatementLastUpdateLog;
  } | null;
}
