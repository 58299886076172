import React from 'react';
import { FormattedMessage } from 'react-intl';
import { faBan } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@skiwo/components';
import { useGetJobQuery } from '../../../Api/Endpoints/Jobs/Jobs.hooks';
import translationKeys from '../../../translations/translationKeys';
import { ManagerJobStatus } from '../../../types';
import useGetJobIdFromParam from '../../hooks/useGetJobIdFromParam';
import { jobSectionVisibilityGuard } from '../../utils/jobSectionVisibilityGuard';
import styles from './ActionButtonsSection.module.scss';

export const ActionButtonsSection = () => {
  const jobId = useGetJobIdFromParam();
  const { data: job } = useGetJobQuery(jobId);
  if (!job) return null;

  return (
    <div className={styles.actionButtons}>
      <div className={styles.buttonsWrapper}>
        {jobSectionVisibilityGuard({
          currentStatus: job.status,
          statuses: [
            ManagerJobStatus.Published,
            ManagerJobStatus.Applied,
            ManagerJobStatus.Accepted,
            ManagerJobStatus.InProgress,
          ],
        }) && (
          <Button
            icon={<FontAwesomeIcon icon={faBan} />}
            variant="gray"
            className={styles.cancelButton}
            size="large"
            disabled
          >
            <FormattedMessage id={translationKeys.job_cancel_button} />
          </Button>
        )}
      </div>
      <div className={styles.buttonsWrapper}></div>
    </div>
  );
};
