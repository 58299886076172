import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { faCalendar } from '@fortawesome/pro-light-svg-icons';
import { faArrowDownToLine } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Banner, Button, DatePicker, Drawer, Tabs, TextField } from '@skiwo/components';
import { stripObject } from '@skiwo/utils';
import { addDays, endOfDay, format, formatISO, startOfDay, subDays } from 'date-fns';
import { ApiError } from '../Api';
import { useApi } from '../providers/ApiProvider';
import translationKeys from '../translations/translationKeys';
import { ManagerJob } from '../types';
import JobsTable from './JobsTable/JobsTable';
import getEndOfNextWorkingDay from './utils/getEndOfNextWorkingDay';
import getJobFilters from './utils/getJobFilters';
import JobsFilterField from './JobsFilterField';
import JobsTab from './JobsTab';
import styles from './Jobs.module.scss';

interface TabItem {
  active?: boolean;
  count?: number;
  id: JobsTab;
  title: string;
  filters: Record<string, string>;
}

type Filters = Record<string, string | string[]>;

const getTabFilters = (tabItems: TabItem[]) => {
  const activeTabItem = tabItems.find((item) => item.active);

  return activeTabItem ? activeTabItem.filters : {};
};

const Jobs = () => {
  const intl = useIntl();
  const api = useApi();
  const [jobs, setJobs] = useState<ManagerJob[]>([]);
  const [page, setPage] = useState(1);
  const [activeTab, setActiveTab] = useState('');
  const [jobsLoading, setJobsLoading] = useState(false);
  const [jobStatsLoading, setJobStatsLoading] = useState(false);
  const [jobsError, setJobsError] = useState<ApiError | null>(null);
  const [jobsPages, setJobsPages] = useState(0);
  const [filters, setFilters] = useState<Filters>({});
  const [showExportDrawer, setShowExportDrawer] = useState(false);
  const [showExportDatePicker, setShowExportDatePicker] = useState(false);
  const [exportDatePeriod, setExportDatePeriod] = useState<Date[]>([]);
  const currentTime = new Date();
  const beginningOfYesterday = startOfDay(subDays(currentTime, 1));
  const endOfToday = endOfDay(currentTime);
  const endOfTomorrow = endOfDay(addDays(currentTime, 1));

  const [tabItems, setTabItems] = useState<TabItem[]>([
    {
      active: true,
      id: JobsTab.Urgent,
      title: intl.formatMessage({ id: translationKeys.jobs_page_urgent_tab }),
      filters: {
        's[status_eq]': 'published',
        's[confirmation_deadline_gteq]': formatISO(beginningOfYesterday),
        's[confirmation_deadline_lteq]': formatISO(endOfToday),
        's[s]': 'start_time asc',
      },
    },
    {
      id: JobsTab.NextUp,
      title: intl.formatMessage({ id: translationKeys.jobs_page_in_progress_tab }),
      filters: {
        's[status_eq]': 'published',
        's[confirmation_deadline_gt]': formatISO(endOfToday),
        's[confirmation_deadline_lteq]': formatISO(getEndOfNextWorkingDay()),
        's[s]': 'start_time asc',
      },
    },
    {
      id: JobsTab.OtherPublished,
      title: intl.formatMessage({ id: translationKeys.jobs_page_other_published_tab }),
      filters: {
        's[status_eq]': 'published',
        's[confirmation_deadline_gteq]': formatISO(endOfTomorrow),
        's[s]': 'start_time asc',
      },
    },
    {
      id: JobsTab.Vip,
      title: intl.formatMessage({ id: translationKeys.jobs_page_vip_tab }),
      filters: {
        's[status_eq]': 'published',
        's[vip_template_eq]': 'true',
        's[s]': 'start_time asc',
      },
    },
    {
      id: JobsTab.All,
      title: intl.formatMessage({ id: translationKeys.jobs_page_all_tab }),
      filters: {
        's[s]': 'id desc',
      },
    },
  ]);

  const [topFilters, setTopFilters] = useState(getTabFilters(tabItems));

  const fetchJobs = async (filters: Filters, page: number, clearCache = false) => {
    const { data, error } = await api.getJobs(
      { page, items: 30, ...stripObject(filters) },
      setJobsLoading,
    );

    if (data?.jobs) {
      let allJobs = jobs;
      setJobsPages(data?.pages || 0);

      if (!clearCache) {
        for (const newJob of data.jobs) {
          if (!allJobs.some((job) => job.id === newJob.id)) {
            allJobs.push(newJob);
          }
        }
      } else {
        allJobs = data.jobs;
      }

      setJobs(allJobs);
      setPage(data.page);
    }

    setJobsError(error);
  };

  const fetchJobStats = async () => {
    const { data } = await api.getJobsStats(setJobStatsLoading);
    if (data) {
      setTabItems((prev) =>
        prev.map((item) => ({
          ...item,
          count: data[item.id] ? parseInt(data[item.id]) : undefined,
        })),
      );
    }
  };

  const fetchWithNewFilters = (newFilters: Filters) => {
    setJobs([]);
    fetchJobs(newFilters, 1, true);
  };

  const handleTabSelect = (newActiveTab: string) => {
    const newTopFilters = tabItems.find((item) => item.id === newActiveTab)?.filters || {};

    setActiveTab(newActiveTab);
    setTopFilters(newTopFilters);
    fetchWithNewFilters({ ...filters, ...newTopFilters });
  };

  const handleFilterChange = (field: JobsFilterField, values: string[]) => {
    const newFilters = getJobFilters(filters, field, values);

    setFilters(newFilters);
    fetchWithNewFilters({ ...topFilters, ...newFilters });
  };

  const handleLoadMore = () => {
    fetchJobs({ ...topFilters, ...filters }, page + 1);
  };

  const handlePresentExportDrawer = () => {
    setShowExportDrawer(true);
  };

  const handleExportDatePickerChange = (start: Date, end?: Date) => {
    if (start && end) {
      setExportDatePeriod([start, end]);
    }
    setShowExportDatePicker(false);
  };

  const getDatePickerValue = () => {
    if (!exportDatePeriod[0]) {
      return '';
    }
    const startDate = format(new Date(exportDatePeriod[0]), 'dd.MM.yy');
    const endDate = format(new Date(exportDatePeriod[1]), 'dd.MM.yy');

    return `${startDate} - ${endDate}`;
  };

  const handleExport = async () => {
    const response = await api.getJobs(
      {
        page,
        's[s]': 'id desc',
        ...stripObject(filters),
        export: 'xlsx',
        's[actual_created_at_gteq]': exportDatePeriod[0].toISOString(),
        's[actual_created_at_lteq]': exportDatePeriod[1].toISOString(),
      },
      setJobsLoading,
    );

    if (response) {
      setShowExportDrawer(false);
      setExportDatePeriod([]);
    }
  };

  useEffect(() => {
    fetchJobStats();
  }, []);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <h1 data-testid="jobs-header">
            <FormattedMessage id={translationKeys.jobs_page_title} />
          </h1>
          <div>
            <Button
              data-testid="jobs-export-button"
              variant="gray"
              icon={<FontAwesomeIcon icon={faArrowDownToLine} />}
              onClick={handlePresentExportDrawer}
            >
              <FormattedMessage id={translationKeys.jobs_page_export} />
            </Button>
          </div>
        </div>

        <div className={styles.tabsContainer}>
          <Tabs items={tabItems} onSelect={handleTabSelect} data-testid="jobs-tabs" />
        </div>

        <div className={styles.tableContainer}>
          <JobsTable
            jobs={jobs}
            activeTab={activeTab}
            error={jobsError}
            isLoading={jobStatsLoading || jobsLoading}
            onFilterChange={handleFilterChange}
          />
        </div>

        {!jobsLoading && jobs && page < jobsPages && (
          <div className={styles.loadMoreButton}>
            <Button
              variant="secondary"
              size="large"
              onClick={handleLoadMore}
              data-testid="load-more-button"
            >
              <FormattedMessage id={translationKeys.jobs_page_load_more_button} />
            </Button>
          </div>
        )}
      </div>

      <Drawer
        title={intl.formatMessage({
          id: translationKeys.jobs_page_export_title,
        })}
        subtitle={intl.formatMessage({
          id: translationKeys.jobs_page_export_description,
        })}
        show={showExportDrawer}
        onClose={() => setShowExportDrawer(false)}
        data-testid="jobs-export-drawer"
      >
        <div className={styles.exportDrawerContent}>
          <div className={styles.datePicker}>
            <TextField
              placeholder={intl.formatMessage({
                id: translationKeys.jobs_page_export_date_picker_placeholder,
              })}
              label={intl.formatMessage({
                id: translationKeys.jobs_page_export_date_picker_label,
              })}
              icon={<FontAwesomeIcon icon={faCalendar} />}
              value={getDatePickerValue()}
              onFocus={() => setShowExportDatePicker(true)}
              onChange={() => setExportDatePeriod([])}
              size="large"
              type="search"
            />
            {showExportDatePicker && (
              <DatePicker
                monthCount={1}
                onClose={() => setShowExportDatePicker(false)}
                onChange={(start: Date, end?: Date) => handleExportDatePickerChange(start, end)}
              />
            )}
          </div>

          <Banner
            variant="information"
            text={intl.formatMessage({
              id: translationKeys.jobs_page_export_info_text,
            })}
          />

          <div className={styles.exportButton}>
            <Button size="large" onClick={handleExport}>
              <FormattedMessage id={translationKeys.jobs_page_export_submit_button} />
            </Button>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default Jobs;
