import { IntlShape } from 'react-intl';
import { TimePickerItem } from '@skiwo/components/src/TimePicker/TimePicker';
import * as yup from 'yup';
import { addTimeToDate } from '../../../CreateInterpretationOrder/utils';
import getRequiredMessage from '../../../helpers/getRequiredFieldMessage';
import translationKeys from '../../../translations/translationKeys';
import { ManagerJobBookingMechanism } from '../../../types';

export interface EditInvoicingFormValues {
  paymentMethodId: string;
  bookingReference?: string;
  paymentBookingReference?: string;
  caseNumber?: string;
  clientNumber?: string;
  clientName?: string;
  pricingTemplateDemanderCustomerId?: string;
  pricingTemplateSupplierHonorarId?: string;
  pricingTemplateSupplierCompanyId?: string;
  actualCreatedAtDate: Date;
  actualCreatedAtTime: TimePickerItem;
  bookingMechanism: ManagerJobBookingMechanism;
  manualBookingFeeApplied?: boolean;
  manualEditingFeeApplied?: boolean;
  manualTravelBookingFeeApplied?: boolean;
  deadlineConfirmationActive?: boolean;
  confirmationByDate?: Date;
  confirmationByTime?: TimePickerItem;

  // For confirmationByDate purposes
  startTime: string;
}

export const schema = (intl: IntlShape) =>
  yup.object().shape({
    paymentMethodId: yup.string().required('Payment method is required'),
    deadlineConfirmationActive: yup.boolean(),
    confirmationByDate: yup
      .date()
      .when(
        ['deadlineConfirmationActive'],
        ([deadlineConfirmationActive]: boolean[] | null[], sch) =>
          deadlineConfirmationActive
            ? sch.required(
                getRequiredMessage(
                  intl,
                  intl.formatMessage({
                    id: translationKeys.create_interpretation_order_manual_booking_date_label,
                  }),
                ),
              )
            : sch.notRequired(),
      ),
    confirmationByTime: yup
      .object()
      .when(
        ['deadlineConfirmationActive'],
        ([deadlineConfirmationActive]: boolean[] | null[], sch) =>
          deadlineConfirmationActive
            ? sch
                .required(
                  getRequiredMessage(
                    intl,
                    intl.formatMessage({
                      id: translationKeys.create_interpretation_order_manual_booking_time_label,
                    }),
                  ),
                )
                .test(
                  'time-difference',
                  intl.formatMessage({
                    id: translationKeys.create_interpretation_order_manual_booking_set_deadline_cant_be_later_error,
                  }),
                  (value, ctx) => {
                    const deadlineDateValue = addTimeToDate(
                      ctx.parent.confirmationByDate,
                      value as TimePickerItem, // TODO refactor with the schema improvement task
                    ).valueOf();
                    const startDateValue = new Date(ctx.parent.startTime).valueOf();

                    return deadlineDateValue < startDateValue;
                  },
                )
            : sch.notRequired(),
      ),
  });
