import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import {
  faArrowDownToLine,
  faBuilding,
  faCalendar,
  faFile,
  faFilePen,
  faTrash,
  faTriangleExclamation,
} from '@fortawesome/pro-solid-svg-icons';
import { faLanguage } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  DatePicker,
  DetailItem,
  FileSelector,
  FileState,
  RadioOptions,
  SearchDropdown,
  SearchDropdownMenuOption,
  TextField,
} from '@skiwo/components';
import { formatFileSize } from '@skiwo/utils';
import { format } from 'date-fns';
import { FormikProps } from 'formik';
import defaultFilePreview from '../../assets/default-file-preview.svg';
import CreateOrderSectionContainer from '../../components/CreateOrderSectionContainer/CreateOrderSectionContainer';
import { useApi } from '../../providers/ApiProvider';
import { useLanguages } from '../../providers/LanguagesProvider';
import { useToast } from '../../providers/ToastProvider/ToastProvider';
import translationKeys from '../../translations/translationKeys';
import { ManagerOrderSubtaskType } from '../../types';
import { CreateTranslationOrderFormValues } from '../CreateTranslationOrder';
import styles from './InformationSection.module.scss';

interface InformationSectionProps {
  sourceFiles: FileState[];
  setSourceFiles: (files: FileState[]) => void;
  noSourceLanguage: boolean;
  setNoSourceLanguage: (value: boolean) => void;
  passwordProtected: boolean;
  setPasswordProtected: (value: boolean) => void;
  selectedTranslationMethodOption?: SearchDropdownMenuOption;
  setSelectedTranslationMethodOption: (value: SearchDropdownMenuOption) => void;
  formikProps: FormikProps<CreateTranslationOrderFormValues>;
}

export const alternativeLanguageNoPreference = 'noPreference';

export const InformationSection = ({
  sourceFiles,
  setSourceFiles,
  noSourceLanguage,
  setNoSourceLanguage,
  passwordProtected,
  setPasswordProtected,
  selectedTranslationMethodOption,
  setSelectedTranslationMethodOption,
  formikProps,
}: InformationSectionProps) => {
  const api = useApi();
  const intl = useIntl();
  const { languages, getLanguageById } = useLanguages();
  const languageOptions =
    languages.map((language) => {
      return { id: language.id, label: language.name || '', key: language.id.toString() };
    }) || [];
  const [showExternalDeadlineDatePicker, setShowExternalDeadlineDatePicker] = useState(false);
  const [selectedTargetLanguages, setSelectedTargetLanguages] =
    useState<SearchDropdownMenuOption[]>();
  const [filePasswords, setFilePasswords] = useState<Record<string, string>>({});
  const [translationMethodOptions, setTranslationMethodOptions] = useState<
    SearchDropdownMenuOption[]
  >([]);
  const { showErrorToast } = useToast();

  const setFilePassword = (fileId: string, password: string | null) => {
    if (password) {
      const passwords = { ...filePasswords, [fileId]: password };

      formikProps.setFieldValue('filePasswords', passwords);
      setFilePasswords(passwords);
    } else {
      const passwords = { ...filePasswords };
      delete passwords[fileId];

      formikProps.setFieldValue('filePasswords', passwords);
      setFilePasswords(passwords);
    }
  };

  const handleExternalDeadlineChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    formikProps.setFieldValue('externalDeadline', e.target.value);
  };

  const handleExternalDeadlineDatePicker = (date: Date) => {
    if (date) {
      formikProps.setFieldValue('externalDeadline', format(date, 'dd.MM.yyyy'));
    }
    setShowExternalDeadlineDatePicker(false);
  };

  const getTranslationMethods = async () => {
    const { data, error } = await api.getTranslationMethods();

    if (data?.translationMethods.length) {
      const options =
        data?.translationMethods.map((method) => {
          return { id: method.id, label: method.name, key: method.id.toString() };
        }) || [];

      setTranslationMethodOptions(options);
    }

    if (error) {
      showErrorToast(error);
    }
  };

  const getAlternativeLanguageOptions = () => {
    if (formikProps.values.targetLanguageIds.length > 1) return [];

    const targetEnglish = formikProps.values.targetLanguageIds.includes('2');
    const targetNorwegianNynorsk = formikProps.values.targetLanguageIds.includes('212');
    const targetNorwegianBookmal = formikProps.values.targetLanguageIds.includes('3');
    const sourceUkrainian = formikProps.values.sourceLanguageId === '43';
    const sourceNotNorwegianBookmal = formikProps.values.sourceLanguageId != '3';

    if (
      (targetEnglish || targetNorwegianNynorsk) &&
      !sourceUkrainian &&
      sourceNotNorwegianBookmal
    ) {
      return [
        {
          id: 1,
          label: intl.formatMessage({
            id: translationKeys.create_translation_order_information_alternative_language_placeholder,
          }),
          key: alternativeLanguageNoPreference,
        },
        {
          id: 3,
          label: getLanguageById(3) || '',
          key: '3',
        },
      ];
    }
    if (sourceUkrainian && (targetNorwegianNynorsk || targetNorwegianBookmal)) {
      return [
        {
          id: 1,
          label: intl.formatMessage({
            id: translationKeys.create_translation_order_information_alternative_language_placeholder,
          }),
          key: alternativeLanguageNoPreference,
        },
        {
          id: 2,
          label: getLanguageById(2) || '',
          key: '2',
        },
      ];
    }

    return [];
  };

  const handleFileDownload = (file: FileState) => {
    const blob = new Blob([file.data], { type: file.type });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = file.name;
    link.click();

    URL.revokeObjectURL(url);
  };

  useEffect(() => {
    getTranslationMethods();
  }, []);

  return (
    <CreateOrderSectionContainer
      icon={<FontAwesomeIcon icon={faLanguage} />}
      title={intl.formatMessage({
        id: translationKeys.create_translation_order_information_label,
      })}
      data-testid="information-section"
    >
      <div className={styles.informationSection}>
        <div className={styles.radioOptionsWrapper}>
          <RadioOptions
            selected={formikProps.values.bookedSubtaskType}
            data-testid="translation-type-button"
            options={[
              {
                id: ManagerOrderSubtaskType.Translation,
                title: intl.formatMessage({
                  id: translationKeys.create_translation_order_information_translation_radio_option,
                }),
                icon: <FontAwesomeIcon icon={faFile} />,
              },
              {
                id: ManagerOrderSubtaskType.Correction,
                title: intl.formatMessage({
                  id: translationKeys.create_translation_order_information_correction_radio_option,
                }),
                icon: <FontAwesomeIcon icon={faFilePen} />,
              },
              {
                id: ManagerOrderSubtaskType.InPerson,
                title: intl.formatMessage({
                  id: translationKeys.create_translation_order_information_in_person_radio_option,
                }),
                icon: <FontAwesomeIcon icon={faBuilding} />,
              },
            ]}
            onSelect={(option) => {
              if (option === ManagerOrderSubtaskType.InPerson) {
                formikProps.setFieldValue('countOfWords', '');
              }
              setNoSourceLanguage(false);
              formikProps.setFieldValue('bookedSubtaskType', option);
            }}
          />
        </div>
        <div>
          <div className={styles.flexRow}>
            {!noSourceLanguage && (
              <div className={styles.languageSelector} data-testid="source-language-dropdown">
                <SearchDropdown
                  options={languageOptions}
                  selected={
                    formikProps.values.sourceLanguageId[0]
                      ? [
                          languageOptions.find((item) => {
                            return item.id.toString() === formikProps.values.sourceLanguageId;
                          }) as SearchDropdownMenuOption,
                        ]
                      : []
                  }
                  label={intl.formatMessage({
                    id: translationKeys.create_translation_order_information_from_label,
                  })}
                  maxResults={200}
                  placeholder={intl.formatMessage({
                    id: translationKeys.create_translation_order_information_from_placeholder,
                  })}
                  errorText={
                    formikProps.touched.sourceLanguageId
                      ? formikProps.errors.sourceLanguageId
                      : undefined
                  }
                  size="large"
                  data-testid="source-language-input"
                  onChange={(language) => {
                    if (language && language.length > 0 && language[0].key) {
                      formikProps.setFieldValue('sourceLanguageId', language[0].id.toString());

                      if (
                        formikProps.values.targetLanguageIds.includes(language[0].id.toString())
                      ) {
                        const filteredTargetLanguages =
                          selectedTargetLanguages?.filter((item) => item.id !== language[0].id) ||
                          [];

                        setSelectedTargetLanguages(filteredTargetLanguages);
                        formikProps.setFieldValue(
                          'targetLanguageIds',
                          filteredTargetLanguages.map((item) => item.id.toString()),
                        );
                      }
                    } else {
                      formikProps.setFieldValue('sourceLanguageId', '');
                    }
                  }}
                />
              </div>
            )}
            <div className={styles.languageSelector} data-testid="target-languages-dropdown">
              <SearchDropdown
                options={languageOptions.filter(
                  (item) => item.id.toString() !== formikProps.values.sourceLanguageId,
                )}
                selected={
                  formikProps.values.targetLanguageIds.length
                    ? languageOptions.filter((item) => {
                        return formikProps.values.targetLanguageIds.includes(item.id.toString());
                      })
                    : []
                }
                maxResults={200}
                label={
                  noSourceLanguage
                    ? intl.formatMessage({
                        id: translationKeys.create_translation_order_information_language_label,
                      })
                    : intl.formatMessage({
                        id: translationKeys.create_translation_order_information_to_label,
                      })
                }
                placeholder={
                  noSourceLanguage
                    ? intl.formatMessage({
                        id: translationKeys.create_translation_order_information_language_placeholder,
                      })
                    : intl.formatMessage({
                        id: translationKeys.create_translation_order_information_to_placeholder,
                      })
                }
                errorText={
                  formikProps.touched.targetLanguageIds
                    ? (formikProps.errors.targetLanguageIds as string)
                    : undefined
                }
                multiple={!noSourceLanguage}
                size="large"
                data-testid="target-language-input"
                onChange={(languages) => {
                  if (languages && languages.length > 0 && languages[0].key) {
                    if (noSourceLanguage) {
                      setSelectedTargetLanguages([languages[0]]);
                      formikProps.setFieldValue('targetLanguageIds', [languages[0].id.toString()]);
                    } else {
                      setSelectedTargetLanguages(languages);
                      formikProps.setFieldValue(
                        'targetLanguageIds',
                        languages.map((language) => language.id.toString()),
                      );
                    }
                  } else {
                    setSelectedTargetLanguages([]);
                    formikProps.setFieldValue('targetLanguageIds', []);
                  }
                }}
              />
            </div>
          </div>

          {!noSourceLanguage && !!getAlternativeLanguageOptions().length && (
            <div className={styles.alternativeLanguageSelector}>
              <SearchDropdown
                label={intl.formatMessage({
                  id: translationKeys.create_translation_order_information_alternative_language_label,
                })}
                selectedKeys={[formikProps.values.alternativeTargetLanguageId]}
                options={getAlternativeLanguageOptions()}
                size="large"
                data-testid="alternative-language-dropdown-input"
                onChange={(alternativeLanguage) => {
                  if (
                    alternativeLanguage &&
                    alternativeLanguage.length > 0 &&
                    alternativeLanguage[0].key
                  ) {
                    formikProps.setFieldValue(
                      'alternativeTargetLanguageId',
                      alternativeLanguage[0].key.toString(),
                    );
                  } else {
                    formikProps.setFieldValue('alternativeTargetLanguageId', '');
                  }
                }}
                hideClearSelected
              />
            </div>
          )}

          {formikProps.values.bookedSubtaskType === ManagerOrderSubtaskType.Correction && (
            <Form.Check
              type="checkbox"
              onChange={(e) => {
                setSelectedTargetLanguages([]);
                formikProps.setFieldValue('sourceLanguageId', '');
                formikProps.setFieldValue('targetLanguageIds', []);
                formikProps.setFieldValue('alternativeTargetLanguageId', '');

                setNoSourceLanguage(e.currentTarget.checked);
              }}
              checked={noSourceLanguage}
              label={intl.formatMessage({
                id: translationKeys.create_translation_order_information_no_source_language_checkbox,
              })}
              className={styles.noSourceLanguageCheckbox}
            />
          )}
        </div>

        {formikProps.values.bookedSubtaskType !== ManagerOrderSubtaskType.InPerson && (
          <div className={styles.fileSection}>
            <Form.Check
              type="switch"
              data-testid="password-protection-checkbox"
              onChange={(e) => setPasswordProtected(e.currentTarget.checked)}
              checked={passwordProtected}
              label={intl.formatMessage({
                id: translationKeys.create_translation_order_information_password_protection_toggle,
              })}
            />

            <>
              <FileSelector
                selectedFiles={sourceFiles}
                multiple
                onSelection={(newFiles) => {
                  setSourceFiles([...sourceFiles, ...newFiles]);
                  formikProps.setFieldValue('attachments', [...sourceFiles, ...newFiles]);
                }}
              />
              {formikProps.touched.attachments && formikProps.errors.attachments && (
                <span className={styles.coverLetterErrorMessage}>
                  <span>
                    <FontAwesomeIcon icon={faTriangleExclamation} />
                  </span>
                  {formikProps.errors.attachments as string}
                </span>
              )}
            </>
            {sourceFiles.length > 0 && (
              <div className={styles.fileList}>
                {sourceFiles.map((file: FileState) => {
                  return (
                    <DetailItem
                      key={file.uid}
                      subtitle={<span>{formatFileSize(file.size)}</span>}
                      title={file.name}
                      preview={
                        file.type.includes('image') ? (
                          <img src={file.preview} height="40" />
                        ) : (
                          <img src={defaultFilePreview} height="40" />
                        )
                      }
                      onActionSelect={(action) => {
                        if (action === 'Download') {
                          handleFileDownload(file);
                        }
                        if (action === 'Delete') {
                          const updatedFiles = sourceFiles.filter(
                            (currentFiles) => currentFiles.uid !== file.uid,
                          );

                          setSourceFiles(updatedFiles);
                          formikProps.setFieldValue('attachments', updatedFiles);
                          setFilePassword(file.uid, null);
                        }
                      }}
                      actions={[
                        {
                          id: 'Download',
                          text: intl.formatMessage({
                            id: translationKeys.create_translation_order_information_file_download_label,
                          }),
                          icon: faArrowDownToLine,
                        },
                        {
                          id: 'Delete',
                          text: intl.formatMessage({
                            id: translationKeys.create_translation_order_information_file_delete_label,
                          }),
                          icon: faTrash,
                          variant: 'critical',
                        },
                      ]}
                    >
                      {passwordProtected && (
                        <div className={styles.filePassword}>
                          {/* Form wrapper turns off the autocomplete. The input autocomplete param doesn't work. */}
                          <form autoComplete="off">
                            <TextField
                              placeholder={intl.formatMessage({
                                id: translationKeys.translation_order_upload_files_password_placeholder,
                              })}
                              type="password"
                              value={filePasswords[file.uid]}
                              onChange={(e) => setFilePassword(file.uid, e.currentTarget.value)}
                              autocomplete={false}
                            />
                          </form>
                        </div>
                      )}
                    </DetailItem>
                  );
                })}
              </div>
            )}
          </div>
        )}

        <div className={styles.flexRow}>
          {formikProps.values.bookedSubtaskType !== ManagerOrderSubtaskType.InPerson && (
            <div className={styles.wordsCountInput}>
              <TextField
                name="countOfWords"
                label={intl.formatMessage({
                  id: translationKeys.create_translation_order_information_word_count_label,
                })}
                placeholder={intl.formatMessage({
                  id: translationKeys.create_translation_order_information_word_count_placeholder,
                })}
                hint={intl.formatMessage({
                  id: translationKeys.create_translation_order_information_word_count_hint,
                })}
                value={formikProps.values.countOfWords}
                size="large"
                type="number"
                data-testid="word-count-input"
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                errorText={
                  formikProps.touched.countOfWords ? formikProps.errors.countOfWords : undefined
                }
              />
            </div>
          )}
          <div className={styles.deadline}>
            <TextField
              label={
                formikProps.values.bookedSubtaskType === ManagerOrderSubtaskType.InPerson
                  ? intl.formatMessage({
                      id: translationKeys.create_translation_order_information_meeting_date_label,
                    })
                  : intl.formatMessage({
                      id: translationKeys.create_translation_order_information_deadline_label,
                    })
              }
              icon={<FontAwesomeIcon icon={faCalendar} />}
              value={
                formikProps.values.externalDeadline ||
                intl.formatMessage({
                  id: translationKeys.create_translation_order_information_deadline_flexible,
                })
              }
              size="large"
              data-testid="deadline-input"
              onFocus={() => setShowExternalDeadlineDatePicker(true)}
              onChange={handleExternalDeadlineChange}
              type="search"
              errorText={
                formikProps.touched.externalDeadline
                  ? formikProps.errors.externalDeadline
                  : undefined
              }
            />
            {showExternalDeadlineDatePicker && (
              <DatePicker
                monthCount={1}
                onClose={() => setShowExternalDeadlineDatePicker(false)}
                singleDate
                onChange={handleExternalDeadlineDatePicker}
              />
            )}
          </div>
        </div>

        <div className={styles.flexRow}>
          <div className={styles.subject} data-testid="subject-dropdown">
            <SearchDropdown
              options={[
                ...translationMethodOptions,
                {
                  id: 0,
                  label: intl.formatMessage({
                    id: translationKeys.create_translation_order_information_subject_other_option,
                  }),
                  key: '0',
                },
              ]}
              size="large"
              data-testid="subject-dropdown-input"
              selected={selectedTranslationMethodOption ? [selectedTranslationMethodOption] : []}
              placeholder={intl.formatMessage({
                id: translationKeys.create_translation_order_information_subject_placeholder,
              })}
              label={intl.formatMessage({
                id: translationKeys.create_translation_order_information_subject_label,
              })}
              onChange={(items: SearchDropdownMenuOption[] | null) => {
                if (items && items.length > 0) {
                  setSelectedTranslationMethodOption(items[0]);
                  formikProps.setFieldValue(
                    'translationMethodId',
                    items[0].id === 0 ? null : items[0].id.toString(),
                  );
                  formikProps.setFieldValue('otherSubject', '');
                }
              }}
              errorText={
                formikProps.touched.translationMethodId
                  ? formikProps.errors.translationMethodId
                  : undefined
              }
              hideClearSelected
            />
          </div>

          <div className={styles.subject}>
            {formikProps.values.translationMethodId === null && (
              <TextField
                name="otherSubject"
                label={intl.formatMessage({
                  id: translationKeys.create_translation_order_information_other_subject_label,
                })}
                placeholder={intl.formatMessage({
                  id: translationKeys.create_translation_order_information_other_subject_placeholder,
                })}
                value={formikProps.values.otherSubject}
                size="large"
                data-testid="word-count-input"
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                errorText={
                  formikProps.touched.otherSubject ? formikProps.errors.otherSubject : undefined
                }
              />
            )}
          </div>
        </div>

        <TextField
          name="instructionsFromDemander"
          label={intl.formatMessage({
            id: translationKeys.create_translation_order_information_description_label,
          })}
          placeholder={intl.formatMessage({
            id: translationKeys.create_translation_order_information_description_placeholder,
          })}
          maxLength={200}
          size="large"
          data-testid="instructions-input"
          onChange={formikProps.handleChange}
          onBlur={formikProps.handleBlur}
          value={formikProps.values.instructionsFromDemander}
          textArea
          hint={intl.formatMessage(
            {
              id: translationKeys.create_translation_order_information_description_hint,
            },
            { charactersLeft: 200 - formikProps.values.instructionsFromDemander.length },
          )}
          errorText={
            formikProps.touched.instructionsFromDemander
              ? formikProps.errors.instructionsFromDemander
              : undefined
          }
        />
      </div>
    </CreateOrderSectionContainer>
  );
};
