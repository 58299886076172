import { IntlShape } from 'react-intl';
import {
  faDesktop,
  faEnvelope,
  faLocationDot,
  faMars,
  faMessages,
  faPhone,
  faTransgender,
  faVenus,
  faVideo,
} from '@fortawesome/pro-solid-svg-icons';
import { defaultPhoneCodeList, SearchDropdownMenuOption } from '@skiwo/components';
import { TimePickerItem } from '@skiwo/components/src/TimePicker/TimePicker';
import { getFormattedAddress } from '@skiwo/utils';
import {
  addHours,
  addMinutes,
  isAfter,
  roundToNearestMinutes,
  setHours,
  setMinutes,
} from 'date-fns';
import generateUniqueIdFromString from '../helpers/generateUniqueIdFromString';
import isBookedOverThreshold from '../helpers/isBookedOverThreshold';
import translationKeys from '../translations/translationKeys';
import {
  Language,
  ManagerJobAutoUpdateToSessionType,
  ManagerJobBookingMechanism,
  ManagerJobDirectProcessing,
  ManagerJobGender,
  ManagerJobGenderProcessing,
  ManagerJobQualificationProcessing,
  ManagerJobSessionType,
  ManagerJobSessionTypeProcessing,
  Qualification,
} from '../types';
import { BaseContactAddress } from '../types/EnterpriseSearchResult';
import { GoogleGeocoderResult } from '../types/Geocoder';
import {
  ManagerJobAlternativeLanguageProcessing,
  ManagerJobBlockedCity,
  ManagerJobDetails,
} from '../types/ManagerJob';
import { QualificationIndustry } from '../types/Qualification';
import { AssignmentInPersonAlternative } from './AssignmentTypeSection/AssignmentTypeInPersonSection/AssignmentTypeInPersonSection';
import {
  AssignmentVideoAlternative,
  AssignmentVideoSolution,
} from './AssignmentTypeSection/AssignmentTypeVideoSection/AssignmentTypeVideoSection';
import { InterpreterMatchOptions } from './SettingsSection/SettingsSection';
import { AddressType, CreateInterpretationOrderFormValues } from './schema';

const getPhoneCodeOption = (phone?: string | null): SearchDropdownMenuOption | undefined => {
  const defaultCodeOption = defaultPhoneCodeList.find((item) => item.id === 47);

  if (!phone) return defaultCodeOption;

  return defaultPhoneCodeList.find(({ key }) => phone.startsWith(key)) ?? defaultCodeOption;
};

export const getCreateInterpretationFormInitialValues = (
  languages: Language[],
  job?: ManagerJobDetails | null,
): CreateInterpretationOrderFormValues => {
  const currentDate = new Date();
  const actualCreatedAtDate = job?.actualCreatedAt ? new Date(job.actualCreatedAt) : currentDate;

  const alternativeLanguage = languages.find(
    (language) => language.id === job?.interpretationRequirement.alternativeLanguageToId,
  );

  const alternativeLanguageOption = alternativeLanguage
    ? {
        id: alternativeLanguage.id,
        key: alternativeLanguage.id.toString(),
        label: alternativeLanguage.name ?? '',
      }
    : undefined;

  const dates = job
    ? [
        {
          date: new Date(new Date(job.interpretationRequirement.startTime).setHours(0, 0, 0, 0)),
          startTime: getTimeOption(new Date(job.interpretationRequirement.startTime)),
          finishTime: getTimeOption(new Date(job.interpretationRequirement.finishTime)),
        },
      ]
    : [
        {
          date: currentDate,
          startTime: getTimeOption(
            roundToNearestMinutes(currentDate, {
              roundingMethod: 'ceil',
              nearestTo: 5,
            }),
          ),
          finishTime: getTimeOption(
            roundToNearestMinutes(addHours(currentDate, 1), {
              roundingMethod: 'ceil',
              nearestTo: 5,
            }),
          ),
        },
      ];

  const phoneCodeOption = getPhoneCodeOption(job?.contact?.phone);

  const getAssignmentVideoSolutionValue = (): AssignmentVideoSolution | undefined => {
    if (!job || job.interpretationRequirement.sessionType === ManagerJobSessionType.Phone) {
      return undefined;
    }

    const isOwnVideo = !!job?.interpretationRequirement.videoPlatformUrl;

    return isOwnVideo ? AssignmentVideoSolution.OwnVideo : AssignmentVideoSolution.SalitaVideo;
  };

  const getContactPersonAddress = () => {
    if (!job?.contact) return;

    const formattedAddress = getFormattedAddress(job.contact);

    if (!formattedAddress) return;

    const generatedId = generateUniqueIdFromString(formattedAddress);

    return {
      id: generatedId,
      label: formattedAddress,
      key: generatedId.toString(),
      customData: job.contact,
    };
  };

  const getBlockedCities = () => {
    if (!job?.blockedCities) return [];

    return job.blockedCities.reduce<SearchDropdownMenuOption<ManagerJobBlockedCity>[]>(
      (prev, city) => {
        const formattedAddress = getFormattedAddress(city);

        if (!formattedAddress) return prev;

        const id = generateUniqueIdFromString(formattedAddress);

        prev.push({
          id,
          label: formattedAddress,
          customData: city,
          key: id.toString(),
        });

        return prev;
      },
      [],
    );
  };

  const areInstructionsAndUrlInitiallyProvided =
    !!job?.interpretationRequirement.videoPlatformUrl &&
    !!job?.interpretationRequirement.videoPlatformInstructions;

  const hasBookingThresholdConfirmation = isBookedOverThreshold(dates);

  return {
    actualCreatedAtDate,
    actualCreatedAtTime: getTimeOption(actualCreatedAtDate),
    alternativeLanguageActive: !!alternativeLanguage,
    alternativeLanguageTo: alternativeLanguageOption,
    attachments: [], // TODO! backend - update next step
    blockCitiesActive: !!job?.blockedCities?.length,
    blockInterpretersActive: !!job?.blockedInterpreters?.length,
    bookingMechanism: job?.bookingMechanism ?? ManagerJobBookingMechanism.Phone,
    bookingReference: job?.info.bookingReference ?? '',
    bookingThresholdConfirmation: hasBookingThresholdConfirmation,
    caseNumber: job?.info.caseNumber ?? '',
    ccActive: false,
    ccEmails: [],
    confirmationPhones: [],
    customerId: job?.owner?.person?.uid ?? '',
    dates,
    departmentId: job?.department?.id.toString() ?? '',
    dialect: job?.interpretationRequirement.dialect ?? '',
    isBookingThresholdConfirmationRequired: hasBookingThresholdConfirmation,
    languageId: job?.interpretationRequirement.languageToId.toString() ?? '',
    manualBookingAutoInvite: true,
    manualBookingStandBy: true,
    manualBookingForever: false,
    manualBookingAutoAward: true,
    messageToInterpreterActive: !!job?.info.description,
    messageToInterpreter: job?.info.description ?? undefined,
    paymentBookingReference: job?.info.paymentBookingReference ?? '',
    paymentMethodId: job?.paymentMethod?.id.toString() ?? '',
    qualificationLevelActive: Boolean(
      job?.interpretationRequirement.specificQualificationRequested,
    ),
    saveAddress: false,
    sessionType: job?.interpretationRequirement.sessionType ?? ManagerJobSessionType.Phone,
    specificGenderActive:
      !!job?.interpretationRequirement.sex &&
      job?.interpretationRequirement.sex !== ManagerJobGender.Both,
    specificInterpreterActive: false,
    subject: job?.info.subject ?? '',
    uploadFilesActive: !!job?.attachments?.length,
    videoPlatformDataRequired: !areInstructionsAndUrlInitiallyProvided || true,
    videoPlatformInstructions: job?.interpretationRequirement.videoPlatformInstructions ?? '',
    videoPlatformUrl: job?.interpretationRequirement.videoPlatformUrl ?? '',
    additionalCustomerUids: job?.attendees ? job.attendees.map(({ uid }) => uid) : [],
    assignmentInPersonAlternative: job
      ? getAssignmentInPersonAlternative(
          job.interpretationRequirement.sessionType,
          job.processRequirement.sessionTypeProcessing,
          job.processRequirement.autoUpdateToSessionType ?? undefined,
        )
      : undefined,
    assignmentVideoAlternative: job
      ? getAssignmentVideoAlternative(
          job.interpretationRequirement.sessionType,
          job.processRequirement.sessionTypeProcessing,
          job.processRequirement.autoUpdateToSessionType ?? undefined,
        )
      : undefined,
    assignmentVideoSolution: getAssignmentVideoSolutionValue(),
    blockedCities: getBlockedCities(),
    blockedInterpreters: job?.blockedInterpreters?.map((interpreter) => ({
      id: generateUniqueIdFromString(interpreter.uid),
      label: interpreter.name,
      key: interpreter.uid,
      customData: interpreter,
    })),
    bookerName: job?.owner?.person?.name ?? '',
    confirmationByDate: job?.interpretationRequirement.confirmationBy
      ? new Date(job.interpretationRequirement.confirmationBy)
      : currentDate,
    confirmationByTime: job?.interpretationRequirement.confirmationBy
      ? getTimeOption(new Date(job.interpretationRequirement.confirmationBy))
      : undefined,
    contactPersonAddress: getContactPersonAddress(),
    contactPersonAddressType: AddressType.Presaved, // this is adjusted in the useEffect in AssignnmentTypeInPersonSection
    contactPersonCountryCode: phoneCodeOption,
    contactPersonDirections: job?.contact?.extra ?? '',
    contactPersonName: job?.contact?.name ?? '',
    contactPersonPhoneNumber: phoneCodeOption
      ? job?.contact?.phone?.replace(phoneCodeOption.label, '')
      : undefined,
    deadlineConfirmationActive: false,
    genderProcessing: job?.processRequirement.genderProcessing,
    jobCategory:
      job?.info.jobCategoryId && job.info.jobCategoryName
        ? {
            id: job.info.jobCategoryId,
            label: job.info.jobCategoryName,
            key: job.info.jobCategoryId.toString(),
          }
        : undefined,
    manualBookingFeeApplied: job?.info.manualBookingFeeApplied ?? false,
    manualEditingFeeApplied: job?.info.manualEditingFeeApplied ?? false,
    manualTravelBookingFeeApplied: job?.info.manualTravelBookingFeeApplied ?? false,
    matchInterpreterWith: undefined,
    qualificationProcessing: job?.processRequirement.qualificationProcessing,
    specificGender:
      !!job?.interpretationRequirement.sex &&
      job.interpretationRequirement.sex !== ManagerJobGender.Both
        ? job.interpretationRequirement.sex
        : undefined,
    specificInterpreter: undefined, //! backend - next step
    specificInterpreterProcessing: undefined, //! backend - next step
    specificQualification:
      job?.interpretationRequirement.specificQualificationRequested &&
      job?.interpretationRequirement.qualificationId
        ? {
            id: job?.interpretationRequirement.qualificationId,
            label: '',
            key: job?.interpretationRequirement.qualificationId.toString(),
          }
        : undefined,
    videoPlatform: job?.interpretationRequirement.videoPlatformId
      ? {
          id: job.interpretationRequirement.videoPlatformId,
          label: '',
          key: job.interpretationRequirement.videoPlatformId.toString(),
        }
      : undefined,
  };
};

export const getAssignmentInPersonAlternative = (
  sessionType: ManagerJobSessionType,
  sessionTypeProcessing: ManagerJobSessionTypeProcessing,
  autoUpdateToSessionType?: ManagerJobAutoUpdateToSessionType,
) => {
  if (sessionType !== ManagerJobSessionType.InPerson) return;

  if (sessionTypeProcessing === ManagerJobSessionTypeProcessing.SessionTypeCancelNotFound) {
    return AssignmentInPersonAlternative.CancelAssignment;
  }

  if (sessionTypeProcessing === ManagerJobSessionTypeProcessing.SessionTypeAutoUpdate) {
    if (autoUpdateToSessionType === ManagerJobAutoUpdateToSessionType.Phone) {
      return AssignmentInPersonAlternative.PhoneInterpreter;
    }

    if (autoUpdateToSessionType === ManagerJobAutoUpdateToSessionType.Video) {
      return AssignmentInPersonAlternative.VideoInterpreter;
    }
  }
};

export const getAssignmentVideoAlternative = (
  sessionType: ManagerJobSessionType,
  sessionTypeProcessing: ManagerJobSessionTypeProcessing,
  autoUpdateToSessionType?: ManagerJobAutoUpdateToSessionType,
) => {
  if (sessionType !== ManagerJobSessionType.Video) return;

  if (sessionTypeProcessing === ManagerJobSessionTypeProcessing.SessionTypeCancelNotFound) {
    return AssignmentVideoAlternative.CancelAssignment;
  }

  if (
    sessionTypeProcessing === ManagerJobSessionTypeProcessing.SessionTypeAutoUpdate &&
    autoUpdateToSessionType === ManagerJobAutoUpdateToSessionType.Phone
  ) {
    return AssignmentVideoAlternative.PhoneInterpreter;
  }
};

export const getSessionTypeProcessing = (
  sessionType: ManagerJobSessionType,
  assignmentVideoAlternative?: AssignmentVideoAlternative | AssignmentInPersonAlternative,
  assignmentInPersonAlternative?: AssignmentVideoAlternative | AssignmentInPersonAlternative,
) => {
  switch (sessionType) {
    case ManagerJobSessionType.Phone:
      return ManagerJobSessionTypeProcessing.SessionTypeNoAction;
    case ManagerJobSessionType.Video:
      if (assignmentVideoAlternative === AssignmentVideoAlternative.CancelAssignment) {
        return ManagerJobSessionTypeProcessing.SessionTypeCancelNotFound;
      }
      if (assignmentVideoAlternative === AssignmentVideoAlternative.PhoneInterpreter) {
        return ManagerJobSessionTypeProcessing.SessionTypeAutoUpdate;
      }
      return;
    case ManagerJobSessionType.InPerson:
      if (assignmentInPersonAlternative === AssignmentInPersonAlternative.CancelAssignment) {
        return ManagerJobSessionTypeProcessing.SessionTypeCancelNotFound;
      }
      if (assignmentInPersonAlternative === AssignmentInPersonAlternative.PhoneInterpreter) {
        return ManagerJobSessionTypeProcessing.SessionTypeAutoUpdate;
      }
      if (assignmentInPersonAlternative === AssignmentInPersonAlternative.VideoInterpreter) {
        return ManagerJobSessionTypeProcessing.SessionTypeAutoUpdate;
      }
      return;
    default:
      return;
  }
};

export const getAutoUpdateToSessionType = (
  sessionType: ManagerJobSessionType,
  assignmentVideoAlternative?: AssignmentVideoAlternative,
  assignmentInPersonAlternative?: AssignmentInPersonAlternative,
) => {
  switch (sessionType) {
    case ManagerJobSessionType.Phone:
      return undefined;
    case ManagerJobSessionType.Video:
      if (assignmentVideoAlternative === AssignmentVideoAlternative.CancelAssignment) {
        return undefined;
      }
      if (assignmentVideoAlternative === AssignmentVideoAlternative.PhoneInterpreter) {
        return ManagerJobAutoUpdateToSessionType.Phone;
      }
      return;
    case ManagerJobSessionType.InPerson:
      if (assignmentInPersonAlternative === AssignmentInPersonAlternative.CancelAssignment) {
        return undefined;
      }
      if (assignmentInPersonAlternative === AssignmentInPersonAlternative.PhoneInterpreter) {
        return ManagerJobAutoUpdateToSessionType.Phone;
      }
      if (assignmentInPersonAlternative === AssignmentInPersonAlternative.VideoInterpreter) {
        return ManagerJobAutoUpdateToSessionType.Video;
      }
      return;
    default:
      return;
  }
};

export const getAddressObjectFromGeocode = (
  address?: GoogleGeocoderResult,
): BaseContactAddress | undefined => {
  if (!address) return;

  const data = address.address_components.reduce(
    (acc, { types, long_name: longName }) => {
      if (types.includes('route')) {
        acc.line1 = acc.line1 ? `${longName} ${acc.line1}` : longName;
      }
      if (types.includes('street_number')) {
        acc.line1 = acc.line1 ? `${acc.line1} ${longName}` : longName;
      }
      if (types.includes('floor')) {
        acc.line2 = longName;
      }
      if (types.includes('locality') || types.includes('postal_town')) {
        acc.city = longName;
      }
      if (types.includes('administrative_area_level_1')) {
        acc.county = longName;
      }
      if (types.includes('country')) {
        acc.country = longName;
      }
      if (types.includes('postal_code')) {
        acc.postcode = longName;
      }
      return acc;
    },
    { line1: '', line2: '', city: '', county: '', country: '', postcode: '' },
  );

  return {
    ...data,
    latitude: address.geometry.location.lat,
    longitude: address.geometry.location.lng,
  };
};

export const getAddressObject = (address?: BaseContactAddress) => {
  if (!address) return;
  return {
    line_1: address.line1,
    line_2: address.line2,
    city: address.city,
    county: address.county,
    country: address.country,
    postcode: address.postcode,
    latitude: address.latitude,
    longitude: address.longitude,
  };
};

export const getTimeOption = (time: Date): TimePickerItem => {
  const currentHour = time.getHours();
  const currentMinute = time.getMinutes();

  return {
    id: currentHour * 60 + currentMinute,
    label: `${currentHour.toString().padStart(2, '0')}:${currentMinute
      .toString()
      .padStart(2, '0')}`,
    hour: currentHour.toString(),
    minute: currentMinute.toString(),
  };
};

export const getTimeOptionWithAddedMinutes = (
  time: TimePickerItem | undefined,
  minutes: number,
): TimePickerItem => {
  const dateNow = new Date();

  if (time) {
    dateNow.setHours(parseInt(time.hour), parseInt(time.minute));
  }

  return getTimeOption(addMinutes(dateNow, minutes));
};

export const getAdjustedFinishTime = (
  currentDate: Date,
  startTime: TimePickerItem | undefined,
  interval = 0,
) => {
  const start = addTimeToDate(currentDate, startTime);

  const hourBeforeMidnight = new Date(currentDate);
  hourBeforeMidnight.setHours(23, 0, 0, 0);

  if (isAfter(start, hourBeforeMidnight)) {
    const startMinutes = start.getMinutes();
    const minutesLeftToMidnight = 60 - startMinutes;

    return getTimeOptionWithAddedMinutes(startTime, minutesLeftToMidnight - interval);
  }

  return getTimeOptionWithAddedMinutes(startTime, 60);
};

export const getAssignmentTypeIcon = (type: ManagerJobSessionType) => {
  switch (type) {
    case ManagerJobSessionType.Phone:
    default:
      return faPhone;
    case ManagerJobSessionType.Video:
      return faVideo;
    case ManagerJobSessionType.InPerson:
      return faLocationDot;
  }
};

export const getAssignmentTypeLabel = (type: ManagerJobSessionType, intl: IntlShape) => {
  switch (type) {
    case ManagerJobSessionType.Phone:
      return intl.formatMessage({
        id: translationKeys.create_interpretation_order_assignment_type_phone_label,
      });
    case ManagerJobSessionType.Video:
      return intl.formatMessage({
        id: translationKeys.create_interpretation_order_assignment_type_video_label,
      });
    case ManagerJobSessionType.InPerson:
      return intl.formatMessage({
        id: translationKeys.create_interpretation_order_assignment_type_in_person_label,
      });
    default:
      return '-';
  }
};

export const getAssignmentAlternativeLabel = (
  intl: IntlShape,
  sessionType: ManagerJobSessionType,
  assignmentVideoAlternative?: AssignmentVideoAlternative,
  assignmentInPersonAlternative?: AssignmentInPersonAlternative,
) => {
  switch (sessionType) {
    case ManagerJobSessionType.Phone:
      return;
    case ManagerJobSessionType.Video:
      if (assignmentVideoAlternative === AssignmentVideoAlternative.CancelAssignment) {
        return intl.formatMessage({
          id: translationKeys.create_interpretation_order_assignment_type_alternative_type_cancel,
        });
      }
      if (assignmentVideoAlternative === AssignmentVideoAlternative.PhoneInterpreter) {
        return intl.formatMessage({
          id: translationKeys.create_interpretation_order_assignment_type_alternative_type_phone,
        });
      }
      return;
    case ManagerJobSessionType.InPerson:
      if (assignmentInPersonAlternative === AssignmentInPersonAlternative.CancelAssignment) {
        return intl.formatMessage({
          id: translationKeys.create_interpretation_order_assignment_type_alternative_type_cancel,
        });
      }
      if (assignmentInPersonAlternative === AssignmentInPersonAlternative.PhoneInterpreter) {
        return intl.formatMessage({
          id: translationKeys.create_interpretation_order_assignment_type_alternative_type_phone,
        });
      }
      if (assignmentInPersonAlternative === AssignmentInPersonAlternative.VideoInterpreter) {
        return intl.formatMessage({
          id: translationKeys.create_interpretation_order_assignment_type_alternative_type_video,
        });
      }
      return;
    default:
      return;
  }
};

export const getGenderIcon = (gender: ManagerJobGender) => {
  switch (gender) {
    case ManagerJobGender.Male:
      return faMars;
    case ManagerJobGender.Female:
      return faVenus;
    case ManagerJobGender.Transgender:
    case ManagerJobGender.Both:
    default:
      return faTransgender;
  }
};

export const getGenderLabel = (gender: ManagerJobGender, intl: IntlShape) => {
  switch (gender) {
    case ManagerJobGender.Male:
      return intl.formatMessage({
        id: translationKeys.male_label,
      });
    case ManagerJobGender.Female:
      return intl.formatMessage({
        id: translationKeys.female_label,
      });
    case ManagerJobGender.Transgender:
      return intl.formatMessage({
        id: translationKeys.transgender_label,
      });
    case ManagerJobGender.Both:
      return intl.formatMessage({
        id: translationKeys.both_gender_label,
      });
  }
};

export const getGenderAlternativeLabel = (
  genderProcessing: ManagerJobGenderProcessing,
  intl: IntlShape,
) => {
  switch (genderProcessing) {
    case ManagerJobGenderProcessing.GenderAutoUpdate:
      return intl.formatMessage({
        id: translationKeys.create_interpretation_order_settings_specific_gender_alternative_find_any_gender,
      });
    case ManagerJobGenderProcessing.GenderCancelNotFound:
      return intl.formatMessage({
        id: translationKeys.create_interpretation_order_settings_specific_gender_alternative_cancel,
      });
    default:
      return '-';
  }
};

export const getQualificationAlternativeLabel = (
  qualificationProcessing: ManagerJobQualificationProcessing,
  intl: IntlShape,
) => {
  switch (qualificationProcessing) {
    case ManagerJobQualificationProcessing.qualification_auto_update:
      return intl.formatMessage({
        id: translationKeys.create_interpretation_order_settings_qualification_level_alternative_find_another_qualification,
      });
    case ManagerJobQualificationProcessing.qualification_cancel_not_found:
      return intl.formatMessage({
        id: translationKeys.create_interpretation_order_settings_qualification_level_alternative_cancel,
      });
    default:
      return '-';
  }
};

export const getSpecificInterpreterAlternativeLabel = (
  directProcessing: ManagerJobDirectProcessing,
  intl: IntlShape,
) => {
  switch (directProcessing) {
    case ManagerJobDirectProcessing.DirectCancelSaidNo:
      return intl.formatMessage({
        id: translationKeys.create_interpretation_order_settings_specific_interpreter_alternative_cancel,
      });
    case ManagerJobDirectProcessing.DirectFindAnother:
      return intl.formatMessage({
        id: translationKeys.create_interpretation_order_settings_specific_interpreter_alternative_find_another,
      });
    default:
      return '-';
  }
};

export const getBookingMechanismIcon = (bookingMechanism: ManagerJobBookingMechanism) => {
  switch (bookingMechanism) {
    case ManagerJobBookingMechanism.Phone:
    default:
      return faPhone;
    case ManagerJobBookingMechanism.Chat:
      return faMessages;
    case ManagerJobBookingMechanism.Email:
      return faEnvelope;
    case ManagerJobBookingMechanism.Platform:
      return faDesktop;
  }
};

export const getBookingMechanismLabel = (
  bookingMechanism: ManagerJobBookingMechanism,
  intl: IntlShape,
) => {
  switch (bookingMechanism) {
    case ManagerJobBookingMechanism.Phone:
      return intl.formatMessage({
        id: translationKeys.create_interpretation_order_booking_mechanism_phone,
      });
    case ManagerJobBookingMechanism.Chat:
      return intl.formatMessage({
        id: translationKeys.create_interpretation_order_booking_mechanism_chat,
      });
    case ManagerJobBookingMechanism.Email:
      return intl.formatMessage({
        id: translationKeys.create_interpretation_order_booking_mechanism_email,
      });
    case ManagerJobBookingMechanism.Platform:
      return intl.formatMessage({
        id: translationKeys.create_interpretation_order_booking_mechanism_platform,
      });
    default:
      return '-';
  }
};

export const getFeesLabels = (
  booking: boolean,
  editing: boolean,
  travel: boolean,
  intl: IntlShape,
) => {
  const labels = [];
  if (booking) {
    labels.push(
      intl.formatMessage({
        id: translationKeys.create_interpretation_order_manual_booking_manual_booking_fee_option,
      }),
    );
  }
  if (editing) {
    labels.push(
      intl.formatMessage({
        id: translationKeys.create_interpretation_order_manual_booking_manual_editing_fee_option,
      }),
    );
  }
  if (travel) {
    labels.push(
      intl.formatMessage({
        id: translationKeys.create_interpretation_order_manual_booking_travel_booking_fee_option,
      }),
    );
  }
  return labels.join(', ');
};

export const getSpecificIntepreterWarningMessage = (
  type: InterpreterMatchOptions,
  intl: IntlShape,
) => {
  switch (type) {
    case InterpreterMatchOptions.Qualification:
      return intl.formatMessage({
        id: translationKeys.create_interpretation_order_settings_specific_interpreter_qualification_warning,
      });
    case InterpreterMatchOptions.Gender:
      return intl.formatMessage({
        id: translationKeys.create_interpretation_order_settings_specific_interpreter_gender_warning,
      });
    case InterpreterMatchOptions.Language:
      return intl.formatMessage({
        id: translationKeys.create_interpretation_order_settings_specific_interpreter_language_warning,
      });
    case InterpreterMatchOptions.SessionType:
      return intl.formatMessage({
        id: translationKeys.create_interpretation_order_settings_specific_interpreter_session_type_warning,
      });
  }
};

export const addTimeToDate = (date: Date, time?: TimePickerItem) => {
  const dateWithHour = setHours(date, parseInt(time?.hour || '0'));
  const dateWithHourAndMinutes = setMinutes(dateWithHour, parseInt(time?.minute || '0'));
  return dateWithHourAndMinutes;
};

export const getInformationOrderData = (
  values: CreateInterpretationOrderFormValues,
  qualifications: Qualification[],
) => {
  const defaultQualificationId = qualifications
    .filter((qualification) => qualification.industry === QualificationIndustry.Interpretation)
    .sort((a, b) => b.priority - a.priority)[0]?.id;

  const contact =
    values.sessionType === ManagerJobSessionType.InPerson
      ? {
          person: {
            name: values.contactPersonName,
            phone:
              values.sessionType === ManagerJobSessionType.InPerson
                ? values.contactPersonCountryCode
                  ? `${values.contactPersonCountryCode.label}${values.contactPersonPhoneNumber}`
                  : values.contactPersonPhoneNumber
                : undefined,
          },
          address:
            values.contactPersonAddressType === AddressType.Google
              ? {
                  ...getAddressObject(values.contactPersonAddress?.customData),
                  extra: values.contactPersonDirections,
                  saveAddress: values.saveAddress,
                }
              : {
                  ...getAddressObject(values.contactPersonAddress?.customData),
                  saveAddress: false,
                },
        }
      : undefined;

  return {
    interpretationRequirement: {
      languageToId: values.languageId,
      startTime: addTimeToDate(values.dates[0].date, values.dates[0].startTime).toString(),
      finishTime: addTimeToDate(values.dates[0].date, values.dates[0].finishTime).toString(),
      qualificationId: values.specificQualification?.id || defaultQualificationId,
      specificQualificationRequested: !!values.specificQualification,
      sessionType: values.sessionType,
      dialect: values.dialect,
      videoPlatformId: values.videoPlatform?.id,
      videoPlatformUrl: values.videoPlatformUrl,
      videoPlatformInstructions: values.videoPlatformInstructions,
      alternativeLanguageToId: values.alternativeLanguageActive
        ? values.alternativeLanguageTo?.id
        : undefined,
      sex: values.specificGenderActive ? values.specificGender : ManagerJobGender.Both,
      blockedCities: values.blockCitiesActive
        ? values.blockedCities?.map((address) => ({
            country: address.customData?.country,
            county: address.customData?.county,
            name: address.customData?.name,
            latitude: address.customData?.latitude,
            longitude: address.customData?.longitude,
          }))
        : undefined,
      ...(contact ? { contact } : {}),
    },
    info: {
      jobCategoryItemId: values.jobCategory?.id,
      subject: values.subject,
      description: values.messageToInterpreterActive ? values.messageToInterpreter : undefined,
    },
    processRequirement: {
      sessionTypeProcessing: getSessionTypeProcessing(
        values.sessionType,
        values.assignmentVideoAlternative,
        values.assignmentInPersonAlternative,
      ),
      autoUpdateToSessionType: getAutoUpdateToSessionType(
        values.sessionType,
        values.assignmentVideoAlternative,
        values.assignmentInPersonAlternative,
      ),
      qualificationProcessing:
        values.qualificationLevelActive && values.specificQualification
          ? values.qualificationProcessing
          : undefined,
      genderProcessing:
        values.specificGenderActive && values.specificGender ? values.genderProcessing : undefined,
      alternativeLanguageProcessing:
        values.alternativeLanguageActive && values.alternativeLanguageTo?.id
          ? ManagerJobAlternativeLanguageProcessing.AlternativeLanguageAutoUpdate
          : ManagerJobAlternativeLanguageProcessing.AlternativeLanguageNoAction,
    },
    blockedInterpreters: values.blockInterpretersActive
      ? values.blockedInterpreters?.map((interpreter) => ({
          uid: interpreter.key,
        }))
      : undefined,
  };
};
