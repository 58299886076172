import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  faArrowDownToLine,
  faArrowRight,
  faCalendar,
  faCircleCheck,
  faCircleDollar,
  faCog,
  faEnvelope,
  faFile,
  faLanguage,
  faMemoPad,
  faPen,
  faPlus,
  faRotateRight,
  faUser,
  faUsers,
  faXmark,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Banner, Breadcrumbs, Button, IconButton } from '@skiwo/components';
import classnames from 'classnames';
import { format } from 'date-fns';
import { useApi } from '../../providers/ApiProvider';
import { useAuth } from '../../providers/AuthProvider';
import { useLanguages } from '../../providers/LanguagesProvider';
import { useToast } from '../../providers/ToastProvider/ToastProvider';
import translationKeys from '../../translations/translationKeys';
import {
  ManagerOrderAttachment,
  ManagerOrderAttachmentStatus,
  ManagerOrderSubtaskDetail,
  ManagerOrderSubtaskStatus,
} from '../../types';
import { ManagerOrderSubtaskDeadlineRequestStatus } from '../../types/ManagerOrderSubtaskDetail';
import GroupedFileList from '../GroupedFileList/GroupedFileList';
import UploadFileDrawer from '../UploadFileDrawer/UploadFileDrawer';
import getSubtaskTypeName from '../utils/getSubtaskTypeName';
import AssignQADrawer from './Drawers/AssignQADrawer/AssignQADrawer';
import AssignSellerDrawer from './Drawers/AssignSellerDrawer/AssignSellerDrawer';
import CustomerDescriptionDrawer from './Drawers/CustomerDescriptionDrawer/CustomerDescriptionDrawer';
import EditSubtaskDrawer from './Drawers/EditSubtaskDrawer/EditSubtaskDrawer';
import InternalReviewDrawer from './Drawers/InternalReviewDrawer/InternalReviewDrawer';
import SubtaskEmailsDrawer from './Drawers/SubtaskEmailsDrawer/SubtaskEmailsDrawer';
import SellerItem from './SellerItem/SellerItem';
import { StatusDropdown } from './StatusDropdown/StatusDropdown';
import styles from './SubtaskDetails.module.scss';

enum SubtaskDrawer {
  EditSubtask = 'EDIT_SUBTASK',
  SubtaskEmails = 'TRANSLATOR_EMAILS',
  CustomerDescription = 'CUSTOMER_DESCRIPTION',
  InternalReview = 'INTERNAL_REVIEW',
  AssignSeller = 'ASSIGN_SELLER',
  AssignQA = 'ASSIGN_QA',
  UploadInitialFiles = 'UPLOAD_INITIAL_FILES',
  UploadWipFiles = 'UPLOAD_WIP_FILES',
  UploadFinalFiles = 'UPLOAD_FINAL_FILES',
}

interface Props {
  orderId: number;
  subtaskId?: number;
  editable: boolean;
  onOrderUpdate: () => void;
}

const SubtaskDetails = (props: Props) => {
  const { orderId, subtaskId, editable, onOrderUpdate } = props;
  const intl = useIntl();
  const [activeDrawer, setActiveDrawer] = useState<SubtaskDrawer | undefined>(undefined);
  const [subtask, setSubtask] = useState<ManagerOrderSubtaskDetail>();
  const { getLanguageById } = useLanguages();
  const [orderAttachments, setOrderAttachments] = useState<ManagerOrderAttachment[]>();
  const [subtaskAttachments, setSubtaskAttachments] = useState<ManagerOrderAttachment[]>();
  const [completedAttachments, setCompletedAttachments] = useState<ManagerOrderAttachment[]>();
  const [changeDeadlineRequestStatusLoading, setChangeDeadlineRequestStatusLoading] =
    useState<boolean>(false);
  const [ocrFilesLoading, setOcrFilesLoading] = useState<boolean>(false);
  const api = useApi();
  const token = localStorage.getItem('token');
  const { showToast, showErrorToast } = useToast();
  const auth = useAuth();

  if (!subtaskId) {
    // TODO: Deal with undefined value
    return null;
  }

  const getOrderSubtask = async () => {
    const { data, error } = await api.getOrderSubtask(subtaskId.toString());

    if (data?.subtask) {
      setSubtask(data.subtask);
    }

    if (error) {
      showErrorToast(error);
    }
  };

  const getOrderAttachments = async () => {
    const { data, error } = await api.getOrderAttachments(orderId.toString(), {
      status: 'initial',
    });

    if (data?.orderAttachments) {
      setOrderAttachments(data.orderAttachments);
    }

    if (error) {
      showErrorToast(error);
    }
  };

  const getSubtaskAttachments = async () => {
    const { data } = await api.getOrderAttachments(orderId.toString(), {
      status: 'wip',
      subtaskId: subtaskId,
    });

    if (data?.orderAttachments) {
      setSubtaskAttachments(data.orderAttachments);
    }
  };

  const getCompletedAttachments = async () => {
    const { data, error } = await api.getOrderAttachments(orderId.toString(), {
      status: 'final',
      subtaskId: subtaskId,
    });

    if (data?.orderAttachments) {
      setCompletedAttachments(data.orderAttachments);
    }

    if (error) {
      showErrorToast(error);
    }
  };

  const onSubtaskUpdate = () => {
    getOrderSubtask();
  };

  const handleChangeStatus = async (status: ManagerOrderSubtaskStatus) => {
    const { data, error } = await api.updateOrderSubtask(subtaskId.toString(), { status });
    if (data) {
      getOrderSubtask();
      onOrderUpdate();
    }

    if (error) {
      showErrorToast(error);
    }
  };

  const downloadFile = (
    fileData: any,
    downloadHeaders: Record<string, string>,
    defaultName: string,
  ) => {
    if (fileData) {
      let fileName = defaultName;
      const disposition = downloadHeaders['content-disposition'];
      if (disposition && disposition.includes('filename=')) {
        const filenameMatch = disposition.match(/filename="?(.+?)"?(;|$)/);
        if (filenameMatch && filenameMatch[1]) {
          fileName = filenameMatch[1];
        }
      }

      const dataBlob = new Blob([fileData], { type: downloadHeaders['content-type'] });
      const url = window.URL.createObjectURL(dataBlob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    }
  };

  const downloadAttachment = async (attachment: ManagerOrderAttachment) => {
    const downloadUrl = `${process.env.REACT_APP_API_BASE_URL}${attachment.link}?disposition=attachment`;

    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = attachment.name;
    link.click();
  };

  const archiveAttachment = async (attachment: ManagerOrderAttachment) => {
    const { error } = await api.updateOrderAttachments(
      orderId.toString(),
      attachment.id.toString(),
      {
        orderAttachment: {
          archived: true,
        },
      },
    );

    if (error) {
      showErrorToast(error);
    } else {
      if (attachment.status === ManagerOrderAttachmentStatus.Initial) {
        getOrderAttachments();
      }
      if (attachment.status === ManagerOrderAttachmentStatus.Wip) {
        getSubtaskAttachments();
      }
      if (attachment.status === ManagerOrderAttachmentStatus.Final) {
        getCompletedAttachments();
      }
    }
  };

  const unarchiveAttachment = async (attachment: ManagerOrderAttachment) => {
    const { error } = await api.updateOrderAttachments(
      orderId.toString(),
      attachment.id.toString(),
      {
        orderAttachment: {
          archived: false,
        },
      },
    );

    if (error) {
      showErrorToast(error);
    } else {
      if (attachment.status === ManagerOrderAttachmentStatus.Initial) {
        getOrderAttachments();
      }
      if (attachment.status === ManagerOrderAttachmentStatus.Wip) {
        getSubtaskAttachments();
      }
      if (attachment.status === ManagerOrderAttachmentStatus.Final) {
        getCompletedAttachments();
      }
    }
  };

  const downloadOrderAttachments = async (status: ManagerOrderAttachmentStatus) => {
    const shouldSendSubtaskId = [
      ManagerOrderAttachmentStatus.Wip,
      ManagerOrderAttachmentStatus.Final,
    ].includes(status);

    const payload = {
      status: status,
      subtaskId: shouldSendSubtaskId ? subtaskId : undefined,
    };

    const { data, headers, error } = await api.downloadOrderAttachments(
      orderId.toString(),
      payload,
    );

    if (data) {
      const fileName = `Order #${orderId}.zip`;
      downloadFile(data, headers, fileName);
    }

    if (error) {
      showErrorToast(error);
    }
  };

  const getOcrAttachments = async () => {
    setOcrFilesLoading(true);

    const { data, error } = await api.getOcrAttachments(orderId.toString());

    if (data && token) {
      const cable = ActionCable.createConsumer(
        `${process.env.REACT_APP_WEB_SOCKET_URL}?jwt=${token}`,
      );

      const ocrResultChannel = cable.subscriptions.create(
        {
          channel: 'OcrResultChannel',
          jwt: token,
          orderId: orderId.toString(),
        },
        {
          received: (message: any) => {
            if (message.ocrStatus === 'failure') {
              showToast({
                message: intl.formatMessage({
                  id: translationKeys.global_generic_error,
                }),
                variant: 'error',
              });
              ocrResultChannel.unsubscribe();
              setOcrFilesLoading(false);
            }
            if (message.ocrStatus === 'success') {
              getOrderAttachments();
              ocrResultChannel.unsubscribe();
              setOcrFilesLoading(false);
            }
          },
        },
      );
    }

    if (error) {
      showErrorToast(error);
      setOcrFilesLoading(false);
    }
  };

  const removeAssignedSeller = async () => {
    const { error } = await api.updateOrderSubtask(subtaskId.toString(), { sellerId: null });

    if (error) {
      showErrorToast(error);
    } else {
      onSubtaskUpdate();
    }
  };

  const removeAssignedQA = async () => {
    const { error } = await api.updateOrderSubtask(subtaskId.toString(), {
      qualityAssurerUid: null,
    });

    if (error) {
      showErrorToast(error);
    } else {
      onSubtaskUpdate();
    }
  };

  const assignMyselfQA = async () => {
    if (!auth.userProfile) return;

    const { error } = await api.updateOrderSubtask(subtaskId.toString(), {
      qualityAssurerUid: auth.userProfile.uid,
    });

    if (error) {
      showErrorToast(error);
    } else {
      onSubtaskUpdate();
    }
  };

  useEffect(() => {
    getOrderSubtask();
    getOrderAttachments();
    getSubtaskAttachments();
    getCompletedAttachments();
  }, []);

  const onDrawerClose = () => {
    setActiveDrawer(undefined);
  };

  const getAttachmentStatusForDrawer = (drawer?: SubtaskDrawer) => {
    if (drawer === SubtaskDrawer.UploadInitialFiles) return ManagerOrderAttachmentStatus.Initial;
    if (drawer === SubtaskDrawer.UploadWipFiles) return ManagerOrderAttachmentStatus.Wip;
    if (drawer === SubtaskDrawer.UploadFinalFiles) return ManagerOrderAttachmentStatus.Final;

    return ManagerOrderAttachmentStatus.Initial;
  };

  const changeDeadlineRequestStatus = async (status: ManagerOrderSubtaskDeadlineRequestStatus) => {
    if (!(subtaskId && subtask?.deadlineChangeRequest?.id)) return;

    const { error } = await api.changeDeadlineRequestStatus(
      subtaskId.toString(),
      subtask.deadlineChangeRequest.id.toString(),
      status,
      setChangeDeadlineRequestStatusLoading,
    );

    if (error) {
      showErrorToast(error);
    } else {
      onSubtaskUpdate();
      getOrderSubtask();
    }
  };

  if (!subtask) {
    // TODO: Deal with undefined value
    return null;
  }

  return (
    <section className={styles.subtaskSection}>
      <Breadcrumbs
        items={[
          {
            id: 'translation_orders',
            title: intl.formatMessage({
              id: translationKeys.translation_order_details_translation_order_label,
            }),
            href: '/translation-subtasks',
          },
          {
            id: orderId.toString(),
            title: `${intl.formatMessage({
              id: translationKeys.translation_order_details_order_label,
            })} #${orderId}`,
            active: true,
          },
        ]}
      />

      {!editable && subtask.status !== ManagerOrderSubtaskStatus.Cancelled && (
        <Banner
          variant="error"
          text={intl.formatMessage({ id: translationKeys.translation_order_no_owner_error_text })}
        />
      )}

      {subtask.status === ManagerOrderSubtaskStatus.Cancelled && (
        <Banner
          data-testid="cancelled-banner"
          variant="error"
          text={intl.formatMessage({
            id: translationKeys.translation_subtask_details_cancelled_banner,
          })}
        />
      )}

      <div className={styles.subtaskHeader}>
        <h2 className={styles.subtaskTitle}>
          <span data-testid="subtask-details-source-language">
            {getLanguageById(subtask.sourceLanguageId)}
          </span>
          <span>
            <FontAwesomeIcon icon={faArrowRight} />
          </span>
          <span data-testid="subtask-details-target-language">
            {getLanguageById(subtask.targetLanguageId)}
          </span>
        </h2>

        <div className={styles.subtaskDetails} data-testid="subtask-details-status-info">
          <StatusDropdown subtask={subtask} onSelect={handleChangeStatus} />
        </div>
      </div>

      <div className={styles.subtaskId}>
        <span>{getSubtaskTypeName(subtask.subtaskType, intl)}</span>
        <span>•</span>
        <span>#{subtask.publicId}</span>
      </div>

      <div className={styles.subtaskInfo}>
        <div className={styles.infoItem}>
          <span className={styles.label}>
            <FormattedMessage
              id={translationKeys.translation_subtask_details_internal_deadline_label}
            />
          </span>
          <span className={styles.value}>
            <FontAwesomeIcon icon={faCalendar} />
            {subtask.internalDeadline
              ? format(new Date(subtask.internalDeadline), 'dd.MM.yyyy')
              : '-'}
          </span>
        </div>
        <div className={styles.infoItem}>
          <span className={styles.label}>
            <FormattedMessage
              id={translationKeys.translation_subtask_details_external_deadline_label}
            />
          </span>
          <span className={styles.value}>
            <FontAwesomeIcon icon={faCalendar} />
            {subtask.externalDeadline
              ? format(new Date(subtask.externalDeadline), 'dd.MM.yyyy')
              : '-'}
          </span>
        </div>
        <div className={styles.infoItem}>
          <span className={styles.label}>
            <FormattedMessage
              id={translationKeys.translation_subtask_details_delivery_date_label}
            />
          </span>
          <span className={styles.value}>
            <FontAwesomeIcon icon={faCalendar} />
            {subtask.deliveredOn ? format(new Date(subtask.deliveredOn), 'dd.MM.yyyy') : '-'}
          </span>
        </div>
        <div className={styles.infoItem}>
          <span className={styles.label}>
            <FormattedMessage
              id={translationKeys.translation_subtask_details_seller_word_count_label}
            />
          </span>
          <span className={styles.value}>
            <FontAwesomeIcon icon={faLanguage} />
            {subtask.countOfWords || '-'}
          </span>
        </div>
        <div className={styles.infoItem}>
          <span className={styles.label}>
            <FormattedMessage id={translationKeys.translation_subtask_details_salita_quote_label} />
          </span>
          <span className={styles.value}>
            <FontAwesomeIcon icon={faCircleDollar} />
            {subtask.platformQuoteAmount} NOK
          </span>
        </div>
        <div className={styles.infoItem}>
          <span className={styles.label}>
            <FormattedMessage id={translationKeys.translation_subtask_details_seller_quote_label} />
          </span>
          <span className={styles.value}>
            <FontAwesomeIcon icon={faCircleDollar} />
            {subtask.sellerQuoteAmount} NOK
            {subtask.deliveredShortNotice && (
              <span className={styles.shortNotice}>
                <FormattedMessage
                  id={translationKeys.translation_subtask_details_short_notice_label}
                />
              </span>
            )}
          </span>
        </div>
      </div>

      <div className={styles.actions}>
        <Button
          variant="gray"
          icon={<FontAwesomeIcon icon={faPen} />}
          onClick={() => setActiveDrawer(SubtaskDrawer.EditSubtask)}
          data-testid="subtask-details-edit-action"
          disabled={!editable}
          size="large"
        >
          <FormattedMessage id={translationKeys.translation_subtask_details_edit_button} />
        </Button>

        <Button
          variant="gray"
          icon={<FontAwesomeIcon icon={faEnvelope} />}
          onClick={() => setActiveDrawer(SubtaskDrawer.SubtaskEmails)}
          data-testid="subtask-details-subtask-emails-action"
          disabled={!subtask.sellerId}
          size="large"
        >
          <FormattedMessage id={translationKeys.translation_subtask_details_subtask_emails_label} />
        </Button>
      </div>

      <Container fluid>
        <Row className={styles.row}>
          {subtask.status === ManagerOrderSubtaskStatus.Invited &&
            subtask.deadlineChangeRequest?.id &&
            subtask.translator?.person?.name && (
              <Col lg={12}>
                <div className={styles.subtaskDeadlineChangeRequest}>
                  <div className={styles.title}>
                    <div>
                      <FontAwesomeIcon className={styles.icon} icon={faCalendar} />
                      <span>
                        <FormattedMessage
                          id={translationKeys.translation_subtask_details_deadline_request_label}
                        />
                      </span>
                    </div>
                  </div>
                  <div className={styles.container}>
                    <SellerItem
                      avatarUrl={subtask.translator?.person?.avatar}
                      name={subtask.translator?.person?.name}
                      qualificationName={subtask.translator?.suitableSkill?.qualification.name}
                      assignedSubtasksCount={subtask.translator?.assignedSubtasksCount}
                      isProactive={subtask.translator?.isProactive}
                      hasContract={subtask.translator?.suitableSkill?.hasContract}
                      status={subtask.status}
                      originalInternalDeadline={
                        subtask.deadlineChangeRequest?.originalInternalDeadline
                      }
                      requestedInternalDeadline={
                        subtask.deadlineChangeRequest?.requestedInternalDeadline
                      }
                      deadlineChangeRequestedAt={subtask.deadlineChangeRequest?.createdAt}
                      deadlineChangeRequestStatus={subtask.deadlineChangeRequest.status}
                      changeDeadlineRequestStatus={changeDeadlineRequestStatus}
                      changeDeadlineRequestStatusLoading={changeDeadlineRequestStatusLoading}
                    />
                  </div>
                </div>
              </Col>
            )}
          <Col lg={4}>
            <div className={styles.section}>
              <div className={styles.title}>
                <div>
                  <FontAwesomeIcon className={styles.icon} icon={faFile} />
                  <span>
                    <FormattedMessage
                      id={translationKeys.translation_subtask_details_source_files_header}
                    />
                  </span>
                </div>

                <div>
                  <IconButton
                    icon={<FontAwesomeIcon className={styles.icon} icon={faRotateRight} />}
                    onClick={() => getOcrAttachments()}
                    hoverText={intl.formatMessage({
                      id: translationKeys.translation_order_files_get_ocr_tooltip,
                    })}
                    isLoading={ocrFilesLoading}
                  />
                  <IconButton
                    icon={<FontAwesomeIcon className={styles.icon} icon={faArrowDownToLine} />}
                    onClick={() => downloadOrderAttachments(ManagerOrderAttachmentStatus.Initial)}
                    hoverText={intl.formatMessage({
                      id: translationKeys.translation_order_files_download_all_tooltip,
                    })}
                    disabled={!(orderAttachments?.length !== 0 && editable)}
                  />
                  <IconButton
                    icon={<FontAwesomeIcon className={styles.icon} icon={faPlus} />}
                    onClick={() => setActiveDrawer(SubtaskDrawer.UploadInitialFiles)}
                    data-testid="subtask-details-upload-file-action"
                    disabled={!editable}
                    hoverText={intl.formatMessage({
                      id: translationKeys.translation_order_files_upload_tooltip,
                    })}
                  />
                </div>
              </div>

              <div className={styles.container}>
                <div className={styles.header}>
                  <div>
                    {/* TODO: Need to update avatar to allow a group */}
                    {subtask.owner?.avatar && (
                      <Avatar
                        size="medium"
                        altText={subtask.owner?.name || ''}
                        url={subtask.owner?.avatar}
                      />
                    )}
                    {subtask.owner?.name && <span>{subtask.owner.name}</span>}
                  </div>
                </div>

                <GroupedFileList
                  files={orderAttachments}
                  onDownload={downloadAttachment}
                  onArchive={archiveAttachment}
                  onUnarchive={unarchiveAttachment}
                />
              </div>
            </div>
          </Col>
          <Col lg={4}>
            <div className={styles.section}>
              <div className={styles.title}>
                <div>
                  <FontAwesomeIcon className={styles.icon} icon={faCog} />
                  <span>
                    <FormattedMessage
                      id={translationKeys.translation_subtask_details_in_progress_header}
                    />
                  </span>
                </div>

                <div>
                  <IconButton
                    icon={<FontAwesomeIcon className={styles.icon} icon={faArrowDownToLine} />}
                    onClick={() => downloadOrderAttachments(ManagerOrderAttachmentStatus.Wip)}
                    disabled={!(subtaskAttachments?.length !== 0 && editable)}
                    hoverText={intl.formatMessage({
                      id: translationKeys.translation_order_files_download_all_tooltip,
                    })}
                  />
                  <IconButton
                    icon={<FontAwesomeIcon className={styles.icon} icon={faPlus} />}
                    onClick={() => setActiveDrawer(SubtaskDrawer.UploadWipFiles)}
                    data-testid="subtask-details-in-progress-upload-file-action"
                    disabled={!editable}
                    hoverText={intl.formatMessage({
                      id: translationKeys.translation_order_files_upload_tooltip,
                    })}
                  />
                </div>
              </div>

              <div className={classnames(styles.container, styles.yellow)}>
                <div className={styles.header}>
                  {!subtask.translator?.person ? (
                    <Button
                      variant="transparent"
                      className={styles.assignButton}
                      icon={
                        <div className={styles.assignButtonIcon}>
                          <FontAwesomeIcon icon={faPlus} />
                        </div>
                      }
                      onClick={() => setActiveDrawer(SubtaskDrawer.AssignSeller)}
                      disabled={!editable}
                    >
                      <span>
                        {intl.formatMessage({
                          id: translationKeys.translation_subtask_details_assign_translator_button,
                        })}
                      </span>
                    </Button>
                  ) : (
                    <>
                      {subtask.translator?.person?.uid && (
                        <Button
                          variant="transparent"
                          className={styles.assignButton}
                          onClick={() => setActiveDrawer(SubtaskDrawer.AssignSeller)}
                          disabled={!editable}
                        >
                          <span className={styles.assignedSeller}>
                            <Avatar
                              size="small"
                              altText={subtask.translator?.person?.name}
                              url={subtask.translator?.person?.avatar}
                            />
                            {subtask.translator?.person?.name}
                          </span>
                        </Button>
                      )}
                      <Button
                        variant="transparent"
                        className={styles.assignButton}
                        onClick={() => removeAssignedSeller()}
                        icon={<FontAwesomeIcon icon={faXmark} />}
                        disabled={!editable}
                      >
                        <FormattedMessage
                          id={translationKeys.translation_subtask_details_unassign_seller_button}
                        />
                      </Button>
                    </>
                  )}
                </div>

                <GroupedFileList
                  files={subtaskAttachments}
                  onDownload={downloadAttachment}
                  onArchive={archiveAttachment}
                  onUnarchive={unarchiveAttachment}
                />
              </div>
            </div>
          </Col>
          <Col lg={4}>
            <div className={styles.section}>
              <div className={styles.title}>
                <div>
                  <FontAwesomeIcon className={styles.icon} icon={faCircleCheck} />
                  <span>
                    <FormattedMessage
                      id={translationKeys.translation_subtask_details_delivery_header}
                    />
                  </span>
                </div>
                <div>
                  <IconButton
                    icon={<FontAwesomeIcon className={styles.icon} icon={faArrowDownToLine} />}
                    onClick={() => downloadOrderAttachments(ManagerOrderAttachmentStatus.Final)}
                    disabled={!(completedAttachments?.length !== 0 && editable)}
                    hoverText={intl.formatMessage({
                      id: translationKeys.translation_order_files_download_all_tooltip,
                    })}
                  />
                  <IconButton
                    icon={<FontAwesomeIcon className={styles.icon} icon={faPlus} />}
                    onClick={() => setActiveDrawer(SubtaskDrawer.UploadFinalFiles)}
                    data-testid="subtask-details-delivery-upload-file-action"
                    disabled={!editable}
                    hoverText={intl.formatMessage({
                      id: translationKeys.translation_order_files_upload_tooltip,
                    })}
                  />
                </div>
              </div>

              <div className={classnames(styles.container, styles.green)}>
                <div className={styles.header}>
                  {subtask.qualityAssurer && Object.keys(subtask.qualityAssurer).length ? (
                    <>
                      <Button
                        variant="transparent"
                        className={styles.assignButton}
                        onClick={() => setActiveDrawer(SubtaskDrawer.AssignQA)}
                        disabled={!editable}
                      >
                        <span className={styles.assignedSeller}>
                          <Avatar
                            size="small"
                            altText={subtask.qualityAssurer.name}
                            url={subtask.qualityAssurer.avatar}
                          />
                          {subtask.qualityAssurer.name}
                        </span>
                      </Button>

                      <Button
                        variant="transparent"
                        className={styles.assignButton}
                        onClick={() => removeAssignedQA()}
                        icon={<FontAwesomeIcon icon={faXmark} />}
                        disabled={!editable}
                      >
                        <FormattedMessage
                          id={translationKeys.translation_subtask_details_unassign_seller_button}
                        />
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        variant="transparent"
                        className={styles.assignButton}
                        icon={
                          <div className={styles.assignButtonIcon}>
                            <FontAwesomeIcon icon={faPlus} />
                          </div>
                        }
                        onClick={() => setActiveDrawer(SubtaskDrawer.AssignQA)}
                        disabled={!editable}
                      >
                        <span>
                          <FormattedMessage
                            id={translationKeys.translation_subtask_details_assign_qa_button}
                          />
                        </span>
                      </Button>

                      <Button
                        variant="transparent"
                        icon={<FontAwesomeIcon icon={faUser} />}
                        size="small"
                        disabled={!editable}
                        onClick={() => assignMyselfQA()}
                      >
                        <FormattedMessage
                          id={translationKeys.translation_subtask_details_assign_myself_button}
                        />
                      </Button>
                    </>
                  )}
                </div>

                <GroupedFileList
                  files={completedAttachments}
                  onDownload={downloadAttachment}
                  onArchive={archiveAttachment}
                  onUnarchive={unarchiveAttachment}
                />
              </div>
            </div>
          </Col>
        </Row>

        <Row className={styles.row}>
          <Col lg={4}>
            <div className={styles.section}>
              <div className={styles.title}>
                <span>
                  <FontAwesomeIcon className={styles.icon} icon={faMemoPad} />
                  <FormattedMessage
                    id={translationKeys.translation_subtask_details_customer_description_header}
                  />
                </span>
              </div>

              <div className={styles.card}>
                <div className={styles.row}>
                  <span className={styles.value}>
                    {subtask.instructionsFromProjectManagerToSeller ||
                      intl.formatMessage({
                        id: translationKeys.translation_subtask_details_no_comments,
                      })}
                  </span>
                </div>

                <div className={styles.actions}>
                  <Button
                    size="large"
                    icon={<FontAwesomeIcon icon={faPen} />}
                    variant="gray"
                    onClick={() => setActiveDrawer(SubtaskDrawer.CustomerDescription)}
                    data-testid="subtask-details-customer-description-action"
                    disabled={!editable}
                  >
                    <FormattedMessage
                      id={translationKeys.translation_subtask_details_edit_button}
                    />
                  </Button>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={4}>
            <div className={styles.section}>
              <div className={styles.title}>
                <span>
                  <FontAwesomeIcon className={styles.icon} icon={faUsers} />
                  <FormattedMessage
                    id={translationKeys.translation_subtask_details_internal_review_header}
                  />
                </span>
              </div>

              <div className={styles.card}>
                <div className={styles.row}>
                  <span className={styles.value}>
                    {subtask.reviewCommentFromProjectManager
                      ? subtask.reviewCommentFromProjectManager
                      : intl.formatMessage({
                          id: translationKeys.translation_subtask_details_no_comments,
                        })}
                  </span>
                </div>

                <div className={styles.actions}>
                  <Button
                    variant="gray"
                    icon={<FontAwesomeIcon icon={faPen} />}
                    onClick={() => setActiveDrawer(SubtaskDrawer.InternalReview)}
                    data-testid="subtask-details-internal-review-action"
                    size="large"
                    disabled={!editable}
                  >
                    <FormattedMessage
                      id={translationKeys.translation_subtask_details_edit_button}
                    />
                  </Button>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={4}>
            <div className={styles.section}>
              <div className={styles.title}>
                <span>
                  <FontAwesomeIcon className={styles.icon} icon={faUser} />
                  <FormattedMessage
                    id={translationKeys.translation_subtask_details_translator_review_header}
                  />
                </span>
              </div>

              <div className={styles.card}>
                <div className={styles.row}>
                  <span className={styles.value}>
                    {subtask.reviewCommentFromSeller
                      ? subtask.reviewCommentFromSeller
                      : intl.formatMessage({
                          id: translationKeys.translation_subtask_details_no_comments,
                        })}
                  </span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <EditSubtaskDrawer
        show={activeDrawer === SubtaskDrawer.EditSubtask}
        subtask={subtask}
        onClose={() => {
          onSubtaskUpdate();
          onDrawerClose();
        }}
      />
      <SubtaskEmailsDrawer
        show={activeDrawer === SubtaskDrawer.SubtaskEmails}
        onClose={onDrawerClose}
        onUpdate={onSubtaskUpdate}
        subtask={subtask}
      />
      <CustomerDescriptionDrawer
        show={activeDrawer === SubtaskDrawer.CustomerDescription}
        onClose={() => {
          onSubtaskUpdate();
          onDrawerClose();
        }}
        subtask={subtask}
      />
      <InternalReviewDrawer
        show={activeDrawer === SubtaskDrawer.InternalReview}
        onClose={() => {
          onSubtaskUpdate();
          onDrawerClose();
        }}
        subtask={subtask}
      />
      <AssignSellerDrawer
        show={activeDrawer === SubtaskDrawer.AssignSeller}
        onClose={() => {
          onSubtaskUpdate();
          onDrawerClose();
        }}
        onSubtaskUpdate={() => {
          onSubtaskUpdate();
          onOrderUpdate();
        }}
        subtask={subtask}
      />
      <AssignQADrawer
        show={activeDrawer === SubtaskDrawer.AssignQA}
        onClose={() => {
          onSubtaskUpdate();
          onDrawerClose();
        }}
        onSubtaskUpdate={() => {
          onSubtaskUpdate();
          onOrderUpdate();
        }}
        subtask={subtask}
      />
      <UploadFileDrawer
        show={
          activeDrawer &&
          [
            SubtaskDrawer.UploadInitialFiles,
            SubtaskDrawer.UploadWipFiles,
            SubtaskDrawer.UploadFinalFiles,
          ].includes(activeDrawer)
        }
        orderId={orderId.toString()}
        subtaskId={subtask.id.toString()}
        attachmentStatus={getAttachmentStatusForDrawer(activeDrawer)}
        onClose={() => {
          if (activeDrawer === SubtaskDrawer.UploadInitialFiles) {
            getOrderAttachments();
          }
          if (activeDrawer === SubtaskDrawer.UploadWipFiles) {
            getSubtaskAttachments();
          }
          if (activeDrawer === SubtaskDrawer.UploadFinalFiles) {
            getCompletedAttachments();
          }

          onSubtaskUpdate();
          onDrawerClose();
        }}
      />
    </section>
  );
};

export default SubtaskDetails;
