import React, { useEffect, useState } from 'react';
import { Form as B_Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  faArrowRight,
  faCircleExclamation,
  faMagicWandSparkles,
  faTriangleExclamation,
  faXmarkCircle,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AvailabilityTimeline, SearchDropdown, SearchDropdownMenuOption } from '@skiwo/components';
import { AvailabilityTimelineMeeting } from '@skiwo/components/src/AvailabilityTimeline/AvailabilityTimeline';
import classNames from 'classnames';
import { areIntervalsOverlapping, compareAsc, format, setDate } from 'date-fns';
import { FormikProps } from 'formik';
import ExpandableToggle from '../../components/ExpandableToggle/ExpandableToggle';
import generateUniqueIdFromString from '../../helpers/generateUniqueIdFromString';
import { getTypesafeSetFieldValue } from '../../helpers/getTypesafeSetFieldValue';
import useDebounce from '../../hooks/useDebounce';
import { useApi } from '../../providers/ApiProvider';
import { useLanguages } from '../../providers/LanguagesProvider';
import translationKeys from '../../translations/translationKeys';
import { ManagerCustomer } from '../../types';
import { Interpreter } from '../../types/Interpreter';
import {
  InterpreterAvailability,
  InterpreterAvailabilityEventType,
} from '../../types/InterpreterAvailability';
import { ManagerJobDirectProcessing } from '../../types/ManagerJob';
import { CreateInterpretationOrderFormValues } from '../schema';
import {
  addTimeToDate,
  getAssignmentTypeIcon,
  getAssignmentTypeLabel,
  getSpecificIntepreterWarningMessage,
} from '../utils';
import styles from './SettingsSection.module.scss';

interface SpecificInterpreterSettingsItemProps {
  customer: Pick<ManagerCustomer, 'emailVerified'>;
  formikProps: FormikProps<CreateInterpretationOrderFormValues>;
}

export enum InterpreterMatchOptions {
  Qualification = 'Qualification',
  Language = 'Language',
  Gender = 'Gender',
  SessionType = 'SessionType',
}

const SpecificInterpreterSettingsItem = ({
  customer,
  formikProps,
}: SpecificInterpreterSettingsItemProps) => {
  const intl = useIntl();
  const api = useApi();
  const { getLanguageById, getDefaultLanguage } = useLanguages();
  const debounceSearch = useDebounce(300);
  const [query, setQuery] = useState('');
  const [pagination, setPagination] = useState({ page: 1, totalPages: 1 });
  const [isInterpretersSearchLoading, setIsInterpretersSearchLoading] = useState(false);
  const [interpretersOptions, setInterpretersOptions] = useState<
    SearchDropdownMenuOption<Interpreter>[]
  >([]);
  const [availabilityMeetings, setAvailabilityMeetings] = useState<AvailabilityTimelineMeeting[]>(
    [],
  );
  const defaultLanguageId = getDefaultLanguage()?.id;
  const setFieldValue = getTypesafeSetFieldValue(formikProps);

  const startTime = addTimeToDate(
    formikProps.values.dates[0].date,
    formikProps.values.dates[0].startTime,
  );

  const endTime = addTimeToDate(
    formikProps.values.dates[0].date,
    formikProps.values.dates[0].finishTime,
  );

  const resetSpecificInterpreterAndShowMessage = (type: InterpreterMatchOptions) => {
    setFieldValue('matchInterpreterWith', type);
    setFieldValue('specificInterpreter', undefined);
    setInterpretersOptions([]);
  };

  const matchInterpreter = () => {
    const interpreter = formikProps.values.specificInterpreter;
    if (!interpreter) {
      setFieldValue('matchInterpreterWith', undefined);
      return;
    }

    if (
      formikProps.values.specificGenderActive &&
      formikProps.values.specificGender &&
      interpreter.customData?.person.gender !== formikProps.values.specificGender
    ) {
      resetSpecificInterpreterAndShowMessage(InterpreterMatchOptions.Gender);
    }

    if (
      formikProps.values.qualificationLevelActive &&
      formikProps.values.specificQualification &&
      !interpreter.customData?.skills.find(
        (skill) => skill.qualificationId === formikProps.values.specificQualification?.id,
      )
    ) {
      resetSpecificInterpreterAndShowMessage(InterpreterMatchOptions.Qualification);
    }

    if (
      formikProps.values.languageId &&
      !interpreter.customData?.skills.find(
        (skill) =>
          skill.languageToId.toString() === formikProps.values.languageId &&
          skill.languageFromId === defaultLanguageId,
      )
    ) {
      resetSpecificInterpreterAndShowMessage(InterpreterMatchOptions.Language);
    }

    if (
      formikProps.values.sessionType &&
      interpreter.customData?.sessionTypes &&
      interpreter.customData?.sessionTypes.length > 0 &&
      !interpreter.customData?.sessionTypes.find(
        (sessionType) => sessionType.name === formikProps.values.sessionType,
      )
    ) {
      resetSpecificInterpreterAndShowMessage(InterpreterMatchOptions.SessionType);
    }
  };

  const getInterpreters = async (query: string, page = 1) => {
    const { data } = await api.searchInterpreters(
      {
        page: page > 0 ? page : 1,
        name: query,
        qualificationIds: formikProps.values.qualificationLevelActive
          ? [formikProps.values.specificQualification?.id]
          : undefined,
        languageIds: formikProps.values.languageId ? [formikProps.values.languageId] : undefined,
        gender: formikProps.values.specificGenderActive
          ? formikProps.values.specificGender
          : undefined,
        sessionTypes: [formikProps.values.sessionType],
      },
      setIsInterpretersSearchLoading,
    );

    if (data) {
      const interpreters =
        data.collection.map((interpreter) => {
          return {
            id: generateUniqueIdFromString(interpreter.person.uid),
            label: interpreter.person.name,
            key: interpreter.person.uid,
            customData: interpreter,
          };
        }) || [];

      const appendData = page > 1;
      setInterpretersOptions((prev) => (appendData ? [...prev, ...interpreters] : interpreters));
      setPagination({ page: data.pagination.page, totalPages: data.pagination.pages });
    }
  };

  const bookingDate = formikProps.values.dates[0].date;
  const bookingStartTime = new Date(bookingDate.setHours(0, 0, 0, 0));
  const bookingEndTime = setDate(
    new Date(bookingDate.setHours(0, 0, 0, 0)),
    bookingDate.getDate() + 1,
  );

  const getAvailability = async (interpreterId?: string) => {
    if (!interpreterId) return;

    const { data } = await api.getInterpreterAvailability(interpreterId, {
      dateFrom: bookingStartTime,
      dateTo: bookingEndTime,
      sessionType: formikProps.values.sessionType,
    });

    if (data) {
      serializeMeetings(data);
    }
  };

  const serializeMeetings = (data: InterpreterAvailability) => {
    const jobMeetings: AvailabilityTimelineMeeting[] = data.jobs.map((job) => {
      return {
        title: getAssignmentTypeLabel(job.sessionType, intl),
        start: new Date(job.startTime),
        end: new Date(job.finishTime),
        variant: 'default',
        icon: <FontAwesomeIcon icon={getAssignmentTypeIcon(job.sessionType)} />,
      };
    });

    const eventMeetings: AvailabilityTimelineMeeting[] = data.events.map((event) => {
      if (event.eventType === InterpreterAvailabilityEventType.StandByTime) {
        return {
          title: 'Standby',
          start: new Date(event.startTime),
          end: new Date(event.finishTime),
          variant: 'green',
          icon: <FontAwesomeIcon icon={faMagicWandSparkles} />,
        };
      } else {
        return {
          title: 'N/A',
          start: new Date(event.startTime),
          end: new Date(event.finishTime),
          variant: 'default',
          icon: <FontAwesomeIcon icon={faXmarkCircle} />,
        };
      }
    });

    const offTimeMeetings: AvailabilityTimelineMeeting[] = data.offTimes.map((offTime) => {
      return {
        start: new Date(offTime.startTime),
        end: new Date(offTime.finishTime),
        title: 'N/A',
        variant: 'default',
        icon: <FontAwesomeIcon icon={faXmarkCircle} />,
      };
    });

    const travelMeetings: AvailabilityTimelineMeeting[] = data.travelTimes.map((travelTime) => {
      return {
        start: new Date(travelTime.startTime),
        end: new Date(travelTime.finishTime),
        variant: 'gray',
      };
    });

    setAvailabilityMeetings([
      ...jobMeetings,
      ...eventMeetings,
      ...offTimeMeetings,
      ...travelMeetings,
    ]);
  };

  const isAvailable = () => {
    if (!formikProps.values.specificInterpreter || compareAsc(startTime, endTime) === 1)
      return false;
    const isOverlapping = availabilityMeetings.some((meeting) => {
      return areIntervalsOverlapping(
        { start: meeting.start, end: meeting.end },
        { start: startTime, end: endTime },
      );
    });

    return !isOverlapping;
  };

  useEffect(() => {
    if (!formikProps.values.specificInterpreterActive) return;

    matchInterpreter();
  }, [
    formikProps.values.languageId,
    formikProps.values.specificGender,
    formikProps.values.specificGenderActive,
    formikProps.values.specificQualification,
    formikProps.values.qualificationLevelActive,
    formikProps.values.sessionType,
  ]);

  useEffect(() => {
    if (!formikProps.values.specificInterpreterActive) return;

    if (formikProps.values.specificInterpreter) {
      getAvailability(formikProps.values.specificInterpreter.customData?.person.uid);
    }
  }, [formikProps.values.dates[0].date]);

  return (
    <ExpandableToggle
      title={intl.formatMessage({
        id: translationKeys.create_interpretation_order_settings_specific_interpreter_title,
      })}
      description={intl.formatMessage({
        id: translationKeys.create_interpretation_order_settings_specific_interpreter_description,
      })}
      active={formikProps.values.specificInterpreterActive}
      action={
        <B_Form.Check
          data-testid="specific-interpreter-switch"
          type="switch"
          checked={formikProps.values.specificInterpreterActive}
          onChange={(e) => setFieldValue('specificInterpreterActive', e.target.checked)}
          disabled={!customer.emailVerified}
        />
      }
      disabled={!customer.emailVerified}
    >
      <span
        className={classNames(styles.additionalInfo, styles.languages)}
        data-testid="translation-from-to"
      >
        {defaultLanguageId && getLanguageById(defaultLanguageId)}
        <FontAwesomeIcon icon={faArrowRight} />
        {getLanguageById(parseInt(formikProps.values.languageId))}
      </span>
      <SearchDropdown
        data-testid="specific-interpreter-dropdown"
        options={interpretersOptions}
        search
        size="large"
        label={intl.formatMessage({
          id: translationKeys.create_interpretation_order_settings_specific_interpreter_label,
        })}
        placeholder={intl.formatMessage({
          id: translationKeys.create_interpretation_order_settings_specific_interpreter_placeholder,
        })}
        selected={
          formikProps.values.specificInterpreter ? [formikProps.values.specificInterpreter] : []
        }
        onSearch={(query: string) => {
          setQuery(query);
          debounceSearch(() => {
            getInterpreters(query);
          });
        }}
        onLoadMore={() => getInterpreters(query, pagination.page + 1)}
        isLoadingMore={isInterpretersSearchLoading}
        pagination={pagination}
        onChange={(item) => {
          if (item && item.length > 0 && item[0].key) {
            const interpreter = item[0];
            setFieldValue('specificInterpreter', interpreter);
            setFieldValue('matchInterpreterWith', undefined);

            getAvailability(interpreter.customData?.person.uid);

            if (
              formikProps.values.sessionType &&
              interpreter.customData?.sessionTypes &&
              interpreter.customData?.sessionTypes.length > 0 &&
              !interpreter.customData?.sessionTypes.find(
                (sessionType) => sessionType.name === formikProps.values.sessionType,
              )
            ) {
              resetSpecificInterpreterAndShowMessage(InterpreterMatchOptions.SessionType);
            }
          } else {
            setFieldValue('specificInterpreter', undefined);
          }
        }}
        errorText={
          formikProps.touched.specificInterpreter
            ? formikProps.errors.specificInterpreter
            : undefined
        }
      />
      {formikProps.values.matchInterpreterWith && (
        <div className={styles.specificInterpreterWarning}>
          <div className={styles.exclamationIcon}>
            <FontAwesomeIcon icon={faCircleExclamation} />
          </div>
          <span>
            {getSpecificIntepreterWarningMessage(formikProps.values.matchInterpreterWith, intl)}
          </span>
        </div>
      )}
      {formikProps.values.specificInterpreterActive &&
        formikProps.values.specificInterpreter &&
        compareAsc(startTime, endTime) !== 1 && (
          <div className={styles.availabilityTimelineWrapper}>
            <span className={styles.availabilityTimelineLabel}>
              <FormattedMessage
                id={
                  translationKeys.create_interpretation_order_settings_specific_interpreter_availability
                }
                values={{ date: format(bookingDate, 'dd.MM.yyyy') }}
              />
            </span>
            <AvailabilityTimeline
              meetings={[
                {
                  start: startTime,
                  end: endTime,
                  variant: 'selected',
                  title: getAssignmentTypeLabel(formikProps.values.sessionType, intl),
                  icon: (
                    <FontAwesomeIcon icon={getAssignmentTypeIcon(formikProps.values.sessionType)} />
                  ),
                },
                ...availabilityMeetings,
              ]}
              startTime={bookingStartTime}
              endTime={bookingEndTime}
              legend={{
                selected: intl.formatMessage({
                  id: translationKeys.create_interpretation_order_settings_specific_interpreter_availability_selected_legend,
                }),
                default: intl.formatMessage({
                  id: translationKeys.create_interpretation_order_settings_specific_interpreter_availability_not_available_legend,
                }),
                green: intl.formatMessage({
                  id: translationKeys.create_interpretation_order_settings_specific_interpreter_availability_standby_legend,
                }),
                gray: intl.formatMessage({
                  id: translationKeys.create_interpretation_order_settings_specific_interpreter_availability_travel_time_legend,
                }),
              }}
            />
            {!isAvailable() && (
              <span className={styles.errorMessage}>
                <span>
                  <FontAwesomeIcon icon={faTriangleExclamation} />
                </span>
                <FormattedMessage
                  id={
                    translationKeys.create_interpretation_order_settings_specific_interpreter_busy_error_message
                  }
                />
              </span>
            )}
          </div>
        )}
      {formikProps.values.specificInterpreterActive && formikProps.values.specificInterpreter && (
        <>
          <span className={styles.radioLabel}>
            <FormattedMessage
              id={translationKeys.create_interpretation_order_settings_if_no_specific_interpreter}
            />
          </span>
          <div className={styles.radioItem}>
            <B_Form.Check
              data-testid="alternative-find-another"
              type="radio"
              label={intl.formatMessage({
                id: translationKeys.create_interpretation_order_settings_specific_interpreter_alternative_find_another,
              })}
              name="specificInterpreterProcessing"
              onChange={() =>
                setFieldValue(
                  'specificInterpreterProcessing',
                  ManagerJobDirectProcessing.DirectFindAnother,
                )
              }
            />
          </div>
          <div className={styles.radioItem}>
            <B_Form.Check
              data-testid="alternative-cancel"
              type="radio"
              label={intl.formatMessage({
                id: translationKeys.create_interpretation_order_settings_specific_interpreter_alternative_cancel,
              })}
              name="specificInterpreterProcessing"
              onChange={() =>
                setFieldValue(
                  'specificInterpreterProcessing',
                  ManagerJobDirectProcessing.DirectCancelSaidNo,
                )
              }
            />
          </div>
          {formikProps.touched.specificInterpreterProcessing &&
            formikProps.errors.specificInterpreterProcessing && (
              <span className={styles.errorMessage}>
                <span>
                  <FontAwesomeIcon icon={faTriangleExclamation} />
                </span>
                {formikProps.errors.specificInterpreterProcessing}
              </span>
            )}
        </>
      )}
    </ExpandableToggle>
  );
};

export default SpecificInterpreterSettingsItem;
