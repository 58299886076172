import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import ChatSection from '../components/ChatSection/ChatSection';
import { useApi } from '../providers/ApiProvider';
import useGetJobIdFromParam from './hooks/useGetJobIdFromParam';
import JobDetails from './JobDetails/JobDetails';
import styles from './Job.module.scss';

export const Job = () => {
  const api = useApi();
  const jobId = useGetJobIdFromParam();

  return (
    <Container fluid className={styles.container}>
      <Row className={styles.row}>
        <Col lg={10} className={styles.section}>
          <JobDetails />
        </Col>
        <Col lg={2} className={styles.section}>
          <ChatSection queryId={jobId} getMessage={api.getJobNotes} sendMessage={api.postJobNote} />
        </Col>
      </Row>
    </Container>
  );
};

export default Job;
