import { useEffect, useState } from 'react';
import { useApi } from '../../../providers/ApiProvider';
import { JobInvoice } from '../../../types/JobInvoice';

export default function useQueryInvoices(jobId?: string | null) {
  const api = useApi();
  const [supplierData, setSupplierData] = useState<JobInvoice>();
  const [demanderData, setDemanderData] = useState<JobInvoice>();
  const [hasSupplierError, setHasSupplierError] = useState(false);
  const [hasDemanderError, setHasDemanderError] = useState(false);

  const queryInvoices = async () => {
    if (!jobId) return;

    await Promise.all([
      api.getJobDemanderInvoice(jobId).catch(() => setHasDemanderError(true)),
      api.getJobSupplierInvoice(jobId).catch(() => setHasSupplierError(true)),
    ]).then(([demander, supplier]) => {
      if (demander?.data) setDemanderData(demander.data);
      if (supplier?.data) setSupplierData(supplier.data);
    });
  };

  useEffect(() => {
    if (!jobId) return;

    queryInvoices();
  }, [jobId]);

  return {
    data: {
      supplier: supplierData,
      demander: demanderData,
    },
    errors: {
      hasSupplierError,
      hasDemanderError,
    },
  };
}
