import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { faEnvelope, faLock } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Banner, Button, Form, TextField } from '@skiwo/components';
import { Formik } from 'formik';
import * as yup from 'yup';
import { setLocale } from 'yup';
import { ApiError } from '../../Api';
import wavingLady from '../../assets/illustration-waving-lady.svg';
import { emailRegex } from '../../helpers/regexPatterns';
import { AuthLayout } from '../../layouts';
import { useApi } from '../../providers/ApiProvider';
import { useAuth } from '../../providers/AuthProvider';
import translationKeys from '../../translations/translationKeys';
import styles from './LogIn.module.scss';

interface FormValues {
  username: string;
  password: string;
}

const phoneRegex = new RegExp(/^\+47\d{8}$/);
const emailOrPhoneRegex = new RegExp(`${phoneRegex.source}|${emailRegex.source}`);

const LogIn = () => {
  const { setToken } = useAuth();
  const navigate = useNavigate();
  const intl = useIntl();
  const api = useApi();
  const [loginError, setLoginError] = useState<ApiError | null>(null);
  const [loginLoading, setLoginLoading] = useState(false);

  setLocale({
    // use constant translation keys for messages without values
    mixed: {
      default: translationKeys.form_error_not_valid,
    },
    number: {
      min: ({ min }) => ({
        key: translationKeys.form_error_min_length,
        values: { minLength: min },
      }),
    },
  });

  const schema = yup.object().shape({
    username: yup
      .string()
      .matches(
        emailOrPhoneRegex,
        intl.formatMessage(
          { id: translationKeys.form_error_not_valid },
          { fieldName: intl.formatMessage({ id: translationKeys.login_username_label }) },
        ),
      )
      .required(
        intl.formatMessage(
          { id: translationKeys.form_error_required },
          { fieldName: intl.formatMessage({ id: translationKeys.login_username_label }) },
        ),
      ),
    password: yup
      .string()
      .required(
        intl.formatMessage(
          { id: translationKeys.form_error_required },
          { fieldName: intl.formatMessage({ id: translationKeys.login_password_label }) },
        ),
      )
      .min(
        8,
        intl.formatMessage(
          { id: translationKeys.form_error_min_length },
          {
            fieldName: intl.formatMessage({ id: translationKeys.login_password_label }),
            minLength: 8,
          },
        ),
      ),
  });

  const handleSubmit = async (values: FormValues) => {
    const { data, error } = await api.signIn(values.username, values.password, setLoginLoading);
    if (error) {
      setLoginError(error);
    }

    if (data) {
      const Token = data.token;

      setToken(Token);
      navigate('/jobs');
    }
  };

  const handleForgotPassword = () => {
    navigate('/forgot-password');
  };

  const getErrorTextForCode = (code: number) => {
    if (code === 401) {
      return 'The email or password was incorrect';
    }

    return 'An error occurred, please try again later';
  };

  return (
    <AuthLayout>
      <Formik
        validationSchema={schema}
        onSubmit={handleSubmit}
        initialValues={{
          username: '',
          password: '',
        }}
      >
        {({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors, dirty }) => (
          <Form
            title={intl.formatMessage({ id: translationKeys.login_header_title })}
            description={intl.formatMessage({ id: translationKeys.login_header_description })}
            image={wavingLady}
            onSubmit={handleSubmit}
          >
            {loginError && (
              <Banner
                variant="error"
                text={getErrorTextForCode(loginError.code)}
                data-testid="error-banner"
              />
            )}

            <TextField
              placeholder={intl.formatMessage({ id: translationKeys.login_username_placeholder })}
              type="text"
              icon={<FontAwesomeIcon icon={faEnvelope} />}
              label={intl.formatMessage({ id: translationKeys.login_username_label })}
              size="large"
              name="username"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.username}
              errorText={touched.username ? errors.username : undefined}
              data-testid="username-input"
            />

            <TextField
              placeholder={intl.formatMessage({ id: translationKeys.login_password_placeholder })}
              type="password"
              icon={<FontAwesomeIcon icon={faLock} />}
              label={intl.formatMessage({ id: translationKeys.login_password_label })}
              size="large"
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.password}
              errorText={touched.password ? errors.password : undefined}
              data-testid="password-input"
            />

            <div className={styles.buttonContainer}>
              <Button
                size="x-large"
                type="submit"
                fullWidth
                disabled={!(isValid && dirty)}
                data-testid="submit-button"
                isLoading={loginLoading}
              >
                <FormattedMessage id={translationKeys.login_button} />
              </Button>

              <Button
                size="large"
                variant="transparent"
                data-testid="forgot-password-button"
                className={styles.forgotPassword}
                onClick={handleForgotPassword}
              >
                <FormattedMessage id={translationKeys.login_forgot_password_button} />
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </AuthLayout>
  );
};

export default LogIn;
